import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Apis from '../../../api'
import Footer from '../Layout/Footer';
import Alert from '../../../helpers/Alert';
import Modal from 'react-bootstrap/Modal'
import swal from 'sweetalert'



function ReasonCode() {
    const [reasonCode, setCode] = React.useState([]);
    const [reasonForm, setReasonForm] = React.useState({
        ref_id: '',
        reason: '',
        description: '',
        id: ''
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: true
    })
    const [refresh, setRefresh] = React.useState(false);
    const [reason_show, setReasonShow] = React.useState(false);
    React.useEffect(() => {
        Apis.reasonCodes().then((res) => {
            var response = res.data.data;
            setCode(response.reason_codes)
        }).catch((error) => {
            console.log(error.response)
        })
        if (reason_show === false) {
            setReasonForm({
                ref_id: '',
                reason: '',
                description: '',
                id: ''
            })
        } else {
            setError({
                ...error,
                error: 'Reason Code is required',
                color: 'error',
                open: false
            })
        }
    }, [refresh, reason_show])

    const handleSubmit = (formData) => {

        if (formData.reason === "") {
            setError({
                ...error,
                error: 'Reason Code is required',
                color: 'error',
                open: true
            })
        } else {
            if (formData.id === "") {
                const raw = {
                    ref_id: formData.ref_id,
                    description: formData.description,
                    reason: formData.reason
                }
                Apis.addReasonCodes(raw).then((res) => {
                    setRefresh(!refresh)
                    setReasonForm({
                        ref_id: '',
                        reason: '',
                        description: '',
                        id: ''
                    })
                    setError({
                        ...error,
                        error: 'Reason Code is created.',
                        color: 'success',
                        open: true
                    })
                    setReasonShow(!reason_show)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: 'Duplicate Reason Code is updated.',
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const raw = {
                    ref_id: formData.ref_id,
                    description: formData.description,
                    reason: formData.reason,
                    id: formData.id
                }
                Apis.updateReasonCodes(raw).then((res) => {
                    setRefresh(!refresh)
                    setReasonForm({
                        ref_id: '',
                        reason: '',
                        description: '',
                        id: ''
                    })
                    setError({
                        ...error,
                        error: 'Reason Code is updated.',
                        color: 'success',
                        open: true
                    })
                    setReasonShow(!reason_show)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: 'Duplicate Reason Code is updated.',
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    const handleEditReason = (data) => {
        setReasonForm({
            ref_id: data.ref_id,
            reason: data.reason,
            description: data.description,
            id: data._id
        })
        setReasonShow(!reason_show)


    }

    const handleDeleteReasonCode = (reasonCodeId, status) => {
        let title = ''
        let message = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
            message = "ReasonCode Move To Archive."
        } else {
            title = "Are you sure want to restore?"
            message = "ReasonCode Restore."
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    id: reasonCodeId
                }
                Apis.deleteReasonCodes(raw).then((res) => {
                    setRefresh(!refresh)
                    setReasonForm({
                        ref_id: '',
                        reason: '',
                        description: '',
                        id: ''
                    })
                    setError({
                        ...error,
                        error: message,
                        color: 'success',
                        open: true
                    })
                }).catch((error) => {
                    console.log(error.response)
                })
            }
        })

    }



    return (
        <>
            <div id="layout-wrapper">
                <Header title='Reason Codes | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='reasonCodes'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">

                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Reason Codes</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Project Attributes</li>
                                            <li className="breadcrumb-item active">Reason Codes</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title mb-4">Reason Codes</h4>
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary"
                                                            onClick={() => setReasonShow(!reason_show)}
                                                        >
                                                            Add<i class="fas fa-plus"></i>
                                                        </button>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Reason Code</th>
                                                                    <th scope="col">Description</th>
                                                                    <th scope="col">Action</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {reasonCode && reasonCode.map((elm, key) => {
                                                                    return (
                                                                        <>
                                                                            <tr>

                                                                                {elm.is_active ? <>
                                                                                    <td>{elm.reason}</td>
                                                                                    <td>{elm.description}</td>
                                                                                    <td>
                                                                                        <div className="d-flex addremove_btn">
                                                                                            {elm._id === '61e5aa3673ef5a540682b237' ? <></> :
                                                                                                <>
                                                                                                    <button type="button" className="btn btn-primary btn-sm me-1" data-bs-placement="bottom" title="Edit" data-bs-whatever="@mdo" onClick={() => handleEditReason(elm)}><i className="fas fa-pencil-alt"></i></button>
                                                                                                    <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteReasonCode(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                                                                                </>}
                                                                                        </div>
                                                                                    </td>
                                                                                </> : <>
                                                                                    <td><strike>{elm.reason}</strike></td>
                                                                                    <td><strike>{elm.description}</strike></td>
                                                                                    <td>
                                                                                        <div className="d-flex addremove_btn">
                                                                                            {elm._id === '61e5aa3673ef5a540682b237' ? <></> :
                                                                                                <>
                                                                                                    <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteReasonCode(elm._id, 1)}><i className="fas fa-trash-restore"></i></button>
                                                                                                </>}
                                                                                        </div>
                                                                                    </td>
                                                                                </>}
                                                                            </tr >
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div >
            <Modal show={reason_show} aria-labelledby="contained-modal-title-vcenter" size="lg" onHide={() => setReasonShow(!reason_show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{reasonForm.id === "" ? 'Add Reason Code' : 'Update Reason Code'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-3" >
                                <label htmlFor="reason" className="col-form-label" >Reason Code</label>
                                <input type="text" name="reason" id="reason" className="form-control" placeholder='Reason' value={reasonForm.reason} onChange={(event) => setReasonForm({ ...reasonForm, reason: event.target.value })} />
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="ref_id" className="col-form-label">Reason Reference Code</label>
                                <input type="text" name="ref_id" id="ref_id" className="form-control" placeholder='RC15' value={reasonForm.ref_id} onChange={(event) => setReasonForm({ ...reasonForm, ref_id: event.target.value })} readOnly={reasonForm.id !== '' ? true : false} />
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div class="mb-3">
                                <label htmlFor="description" class="col-form-label">Description</label>
                                <textarea id="description" name="description" rows="3" class="form-control" placeholder="Enter Description" value={reasonForm.description} onChange={(event) => setReasonForm({ ...reasonForm, description: event.target.value })}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div >
                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => handleSubmit(reasonForm)}>{reasonForm.id === "" ? 'Save' : 'Update'}</button>
                    </div>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ReasonCode
