import axios from 'axios'
const config = require('../config')

const api = axios.create({
    baseURL: config.api_url + '/api',
})

/* Profile Api Routes */
export const userlogin = payload => api.post('/userlogin', payload)
export const forgetUser = payload => api.post('/forget-user', payload)
export const resetPassword = payload => api.post('/reset-password', payload)
export const verifyEmail = payload => api.post('/verify-email', payload)
export const changePassword = payload => api.post('/update-password', payload)
export const updateSystemPassword = payload => api.post('/update-sys-info', payload)
export const checkSystemInfo = payload => api.post('/check-system-info', payload)

/* ReasonCodes Api Routes */
export const reasonCodes = payload => api.get('/fetch-reason-codes')
export const addReasonCodes = payload => api.post('/add-reason-codes', payload)
export const updateReasonCodes = payload => api.post('/update-reason-codes', payload)
export const deleteReasonCodes = payload => api.post('/delete-reason-codes', payload)

/* Attributes Api Routes */
export const getAttribute = payload => api.get('/attributes')
export const addAttribute = payload => api.post('/add-attribute', payload)
export const updateAttribute = payload => api.post('/edit-attribute', payload)
export const deleteAttribute = payload => api.post('/delete-attribute', payload)

/* Team Api Routes */
export const getTeam = payload => api.get('/get-team')
export const addTeam = payload => api.post('/add-team', payload)
export const deleteTeam = payload => api.post('/delete-team', payload)
export const updateTeam = payload => api.post('/edit-team', payload)

/* Member Api Routes */
export const getMember = payload => api.get('/get-member')
export const addMember = payload => api.post('/add-member', payload)
export const deleteMember = payload => api.post('/delete-member', payload)
export const updateMember = payload => api.post('/edit-member', payload)

/* SuAdmin Api Routes */
export const getSubAdmin = payload => api.get('/get-sub-admin');
export const addSubAdmin = payload => api.post('/add-sub-admin', payload);
export const updateSubAdmin = payload => api.post('/edit-sub-admin', payload);

/* Association Routes */
export const getAssociation = payload => api.get('/get-association')
export const addAssociation = payload => api.post('/add-association', payload)
export const deleteAssociation = payload => api.post('/delete-association', payload)

/* Project Setting Routes */
export const getCalendar = payload => api.get('/get-calender')
export const updateCalender = payload => api.post('/update-calender', payload)

/* Projects Routes */
export const getProject = payload => api.post('/get-projects', payload)
export const addProject = payload => api.post('/add-project', payload)
export const updateProject = payload => api.post('/update-project', payload)
export const lockProject = payload => api.post('/lock-project', payload)

/* Plans Routes*/
export const getPlan = payload => api.post('/get-plans', payload)
export const addPlan = payload => api.post('/add-plan', payload)
export const updatePlan = payload => api.post('/update-plan', payload)
export const lockPlan = payload => api.post('/lock-plan', payload)
export const getAllPlan = payload => api.get('/get-all-plans', payload)

/*Cost Attributes Api Routes */
export const getCostAttribute = payload => api.get('/cost-attributes')
export const addCostAttribute = payload => api.post('/add-cost-attribute', payload)
export const updateCostAttribute = payload => api.post('/edit-cost-attribute', payload)
export const deleteCostAttribute = payload => api.post('/delete-cost-attribute', payload)

/*Cost Codes Api Routes */
export const getCostCodes = payload => api.get('/cost-codes')
export const addCostCode = payload => api.post('/add-cost-code', payload)
export const updateCostCode = payload => api.post('/edit-cost-code', payload)
export const deleteCostCode = payload => api.post('/delete-cost-code', payload)

/*Cost Component Api Routes */
export const getCostComponents = payload => api.get('/cost-components')
export const addCostComponent = payload => api.post('/add-cost-component', payload)
export const updateCostComponent = payload => api.post('/edit-cost-component', payload)
export const deleteCostComponent = payload => api.post('/delete-cost-component', payload)

/* Cost Track Api Routes */
export const getCostTrackMp = payload => api.get('/cost-track-mp')
export const addCostTrackMp = payload => api.post('/add-cost-track-mp', payload)
export const editCostTrackPrice = payload => api.post('/edit-cost-track-price', payload)

/* Task Api Routes */
export const getTask = payload => api.get('/get-task')
export const addTask = payload => api.post('/add-task', payload)
export const updateTask = payload => api.post('/update-task', payload)
export const updateTaskDrag = payload => api.post('/update-task-drag', payload)
export const deleteTask = payload => api.post('/delete-task', payload)
export const createLink = payload => api.post('/create-link', payload)
export const deleteLink = payload => api.post('/delete-link', payload)
export const taskfilter = payload => api.post('/get-task-filter', payload)
export const resizeTask = payload => api.post('/get-task-resize', payload)
export const postionTask = payload => api.post('/set-task-postion', payload)
export const bulkTaskDrag = payload => api.post('/update-bulk-task-drag', payload)
export const getTaskLog = payload => api.post('/get_task_log', payload)

/* Commitment APi Routes */
export const commitmentTask = payload => api.post('/get-commitment-plans', payload)
export const addcommitmentplan = payload => api.post('/add-commitment-plan', payload)
export const closecommitmentplan = payload => api.post('/close-commitment-plan', payload)
export const closecommitmentplannotcomplete = payload => api.post('/close-commitment-plan-not-complete', payload)
export const updateCommitmentTask = payload => api.post('/update-commitment-task', payload)
export const updateCommitTask = payload => api.post('/update-commit-task', payload)

/* Route for Analytics And Reports */

export const getAnalytice = payload => api.post('/get-analytics', payload)
export const getCommitmentReport = payload => api.post('/get-commitment-report', payload)

/* Route for Notes */

export const getNotes = payload => api.post('/get_notes', payload)
export const addNotes = payload => api.post('/add_notes', payload)
export const updateNotes = payload => api.post('/update_notes', payload)

/*  */
export const taskVarianceFilter = payload => api.post('/get-variance-filter', payload)

/* Apis for Constraints Log */
export const getConstraint = payload => api.get('/get-constraint-log')
export const addConstraint = payload => api.post('/add-constraint-log', payload)
export const updateConstraintCheckedList = payload => api.post('/update-checked-list', payload)
export const updateConstraintsPosition = payload => api.post('/update-constraints-position', payload)
export const updateConstraintsChecklist = payload => api.post('/update-constraints-checklist', payload)
export const updateConstraint = payload => api.post('/update-constraints', payload)
export const addConstraintComment = payload => api.post('/add-constraints-comment', payload)

/* Apis For Material Manager Suppliers */
export const getSuppliers = payload => api.get('/get-supplier')
export const addSuppliers = payload => api.post('/add-supplier', payload)
export const updateSupplier = payload => api.post('/update-supplier', payload)
export const deleteSupplier = payload => api.post('/delete-supplier', payload)

/*Material Attributes Api Routes */
export const getmaterialAttribute = payload => api.get('/material-attributes')
export const addmaterialAttribute = payload => api.post('/add-material-attribute', payload)
export const updatematerialAttribute = payload => api.post('/edit-material-attribute', payload)
export const deletematerialAttribute = payload => api.post('/delete-material-attribute', payload)

/* Material Equipment Api Routes */
export const getMaterialEquipment = payload => api.get('/get-material-equipment')
export const addMaterialEquipment = payload => api.post('/add-material-equipment', payload)
export const updateMaterialEquiment = payload => api.post('/edit-material-equipment', payload)
export const deleteMaterialEquiment = payload => api.post('/delete-material-equipment', payload)
/* Material  Api Routes */
export const getMaterial = payload => api.get('/get-material')
export const getMaterialBySuppliers = payload => api.post('/get-material-by-supplier', payload)
export const addMaterial = payload => api.post('/add-material', payload)
export const updateMaterial = payload => api.post('/edit-material', payload)
export const deleteMaterial = payload => api.post('/delete-material', payload)

/* Task Material Api Routes */
export const getTaskMaterial = payload => api.post('/get-task-material', payload)
export const getTaskMaterialByFilter = payload => api.post('/get-task-materials-by-filter', payload)
export const addTaskMaterial = payload => api.post('/add-task-material', payload)
export const updateTaskMaterial = payload => api.post('/edit-task-material', payload)
export const deleteTaskMaterial = payload => api.post('/delete-task-material', payload)
export const getAllTaskMaterial = payload => api.get('/get-all-task-material')
export const placeTaskMaterialOreded = payload => api.post('/ordered-task-material', payload)

/* Task Equipment APi Routes */
export const getAllTaskEquipment = payload => api.get('/get-all-task-equipment')
export const getTaskEquipment = payload => api.post('/get-task-equipment', payload)
export const addTaskEquipment = payload => api.post('/add-task-equipment', payload)
export const updateTaskEquipment = payload => api.post('/edit-task-equipment', payload)
export const deleteTaskEquipment = payload => api.post('/delete-task-equipment', payload)
export const placeTaskEquipmentOreded = payload => api.post('/ordered-task-equipment', payload)
export const getTaskEquipmentByFilter = payload => api.post('/get-task-equipment-by-filter', payload)

/* Process Mapper Api Routes */
export const getProcess = (payload) => api.post("/get-process-map", payload);
export const addProcess = (payload) => api.post("/add-process-map", payload);
export const updateProcess = (payload) => api.post("/update-process-map", payload);
export const deleteProcess = (payload) => api.post("/delete-process-map", payload);
export const uploadFiles = (payload) => api.post("/upload-process", payload);
export const getUploadFiles = (payload) => api.post("/get-uploaded-process", payload);

/* Login Image And Other */
export const getSetting = (payload) => api.get('/get-setting');
export const addSetting = (payload) => api.post('/add-project-setting', payload)
export const addLogingImage = (payload) => api.post('/add-loging-image', payload)
export const updateLogingImage = (payload) => api.post('/update-loging-image', payload)



const Apis = {
    userlogin,
    forgetUser,
    resetPassword,
    verifyEmail,
    changePassword,
    reasonCodes,
    addReasonCodes,
    updateReasonCodes,
    deleteReasonCodes,
    getAttribute,
    addAttribute,
    deleteAttribute,
    updateAttribute,
    updateSystemPassword,
    checkSystemInfo,
    getTeam,
    addTeam,
    deleteTeam,
    updateTeam,
    getAssociation,
    addAssociation,
    deleteAssociation,
    getMember,
    addMember,
    deleteMember,
    updateMember,
    getCalendar,
    updateCalender,
    getProject,
    addProject,
    updateProject,
    lockProject,
    getAllPlan,
    getPlan,
    addPlan,
    updatePlan,
    lockPlan,
    getCostAttribute,
    addCostAttribute,
    updateCostAttribute,
    deleteCostAttribute,
    getCostCodes,
    addCostCode,
    updateCostCode,
    deleteCostCode,
    getCostComponents,
    addCostComponent,
    updateCostComponent,
    deleteCostComponent,
    getCostTrackMp,
    addCostTrackMp,
    editCostTrackPrice,
    getTask,
    addTask,
    updateTask,
    updateTaskDrag,
    deleteTask,
    createLink,
    taskfilter,
    commitmentTask,
    addcommitmentplan,
    closecommitmentplan,
    deleteLink,
    resizeTask,
    postionTask,
    bulkTaskDrag,
    closecommitmentplannotcomplete,
    getAnalytice,
    updateCommitmentTask,
    updateCommitTask,
    getCommitmentReport,
    getTaskLog,
    getNotes,
    addNotes,
    updateNotes,
    taskVarianceFilter,
    getConstraint,
    addConstraint,
    updateConstraintCheckedList,
    updateConstraintsPosition,
    updateConstraintsChecklist,
    updateConstraint,
    addConstraintComment,
    getSuppliers,
    addSuppliers,
    updateSupplier,
    deleteSupplier,
    getmaterialAttribute,
    addmaterialAttribute,
    updatematerialAttribute,
    deletematerialAttribute,
    getMaterialEquipment,
    addMaterialEquipment,
    updateMaterialEquiment,
    deleteMaterialEquiment,
    getMaterial,
    addMaterial,
    updateMaterial,
    deleteMaterial,
    getTaskMaterial,
    addTaskMaterial,
    updateTaskMaterial,
    deleteTaskMaterial,
    getTaskEquipment,
    addTaskEquipment,
    updateTaskEquipment,
    deleteTaskEquipment,
    getAllTaskMaterial,
    placeTaskMaterialOreded,
    getAllTaskEquipment,
    placeTaskEquipmentOreded,
    getTaskMaterialByFilter,
    getMaterialBySuppliers,
    getTaskEquipmentByFilter,
    getProcess,
    addProcess,
    updateProcess,
    deleteProcess,
    uploadFiles,
    getUploadFiles,
    getSetting,
    updateLogingImage,
    addLogingImage,
    addSetting,
    getSubAdmin,
    addSubAdmin,
    updateSubAdmin
}

export default Apis