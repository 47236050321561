import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Logout from '../../App/Logout';

function Header(props) {

    const { toggle, handleLogout, toggleFullscreen, toggleSidebar } = Logout()
    const user = JSON.parse(localStorage.getItem('user-info'))


    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="/assets/images/logo-sm.png" alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="/assets/images/logo-dark.png" alt="" height="17" />
                                </span>
                            </Link>
                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="/assets/images/logo-sm.png" alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="/assets/images/logo-light.png" alt="" height="18" />
                                </span>
                            </Link>
                        </div>
                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                            onClick={() => toggleSidebar(toggle)}
                        >
                            <i className="mdi mdi-menu"></i>
                        </button>
                    </div>
                    <div className="d-flex">
                        <div className="dropdown d-none d-lg-inline-block">
                            <button type="button" className="btn header-item noti-icon waves-effect" onClick={toggleFullscreen} data-bs-toggle="fullscreen">
                                <i className="mdi mdi-fullscreen"></i>
                            </button>
                        </div>
                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                <i className='mdi mdi-account-circle font-size-36 align-middle mr-1' style={{ fontSize: '36px' }} />
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                {user.role === 'admin' ? <>
                                    <Link className="dropdown-item" to="/change-system-password"><i className="mdi mdi-alert font-size-17 align-middle me-1"></i> Change System Password</Link>
                                    <Link className="dropdown-item" to="/change-password"><i className="mdi mdi-lock font-size-17 align-middle me-1"></i> Change Your Password</Link>
                                    <Link className="dropdown-item" to="/manage-sub-admin"><i className="fa fa-user-plus font-size-17 align-middle me-1"></i> Manage Sub Admins</Link>
                                    <Link className="dropdown-item" to="/system-settings"><i className="fa fa-cog font-size-17 align-middle me-1"></i> Settings</Link>
                                </> : <></>}
                                {user.role === 'sub_admin' ? <>
                                    <Link className="dropdown-item" to="/change-system-password"><i className="mdi mdi-alert font-size-17 align-middle me-1"></i> Change System Password</Link>
                                    <Link className="dropdown-item" to="/change-password"><i className="mdi mdi-lock font-size-17 align-middle me-1"></i> Change Your Password</Link>
                                    <Link className="dropdown-item" to="/system-settings"><i className="fa fa-cog font-size-17 align-middle me-1"></i> Settings</Link>
                                </> : <></>}


                                <div className="dropdown-divider"></div>
                                <Link to="#" className="dropdown-item text-danger" style={{ cursor: 'pointer' }} onClick={handleLogout}><i className="mdi mdi-logout bx bx-power-off font-size-17 align-middle me-1 text-danger"></i> Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
