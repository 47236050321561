import React from 'react'
import Apis from '../../../api'
import validator from 'validator'
import swal from 'sweetalert'



const CollaboratorApi = (callback) => {
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const [teamList, setTeam] = React.useState([])
    const [calander, setCalander] = React.useState([])
    const [PROJECT_CALENDAR_DAYS, setDays] = React.useState()
    const [memberList, setMember] = React.useState([])
    const [associationList, setAssociation] = React.useState([])
    const [values, setValues] = React.useState({
        id: '',
        team_name: '',
        team_abrv: '',
        team_handle: '',
        team_planning: '',
        color: '#4D4D4D',
        first_name: '',
        last_name: '',
        member_abrv: '',
        member_handle: '',
        email: '',
        team_id: '',
        member_id: '',
        role: '',
        username: '',
        team: '',
        member: '',
    })

    React.useEffect(() => {
        TeamList()
        MemberList()
        AssociationList()
        getCalendar()
    }, [])

    const [state, setState] = React.useState({
        color: '#4D4D4D',
        show_color_picker: false,
        isProjectCalendarEdited: false,
        isProjectCalendarLoading: false,
        projectCalendarValues: [],
    })

    const handleClick = () => {
        setState({ ...state, show_color_picker: !state.show_color_picker })
    }

    const handleAdd = () => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        setValues({
            id: '',
            team_name: '',
            team_abrv: '',
            team_handle: '',
            team_planning: '',
            color: '#4D4D4D',
            first_name: '',
            last_name: '',
            member_abrv: '',
            member_handle: '',
            email: '',
            team_id: '',
            member_id: '',
            role: '',
            username: '',
            member: '',
            team: ''
        })

    }

    const handleColorChange = (color) => {
        setValues({
            ...values,
            color: color.hex
        })

        setState({ ...state, color: color.hex, show_color_picker: !state.show_color_picker })
    }

    /* Project Calander */
    const getCalendar = () => {
        Apis.getCalendar().then((res) => {
            setCalander(res.data.data)
        }).catch(error => {
            console.log(error.response.data)
        })

    }

    React.useEffect(() => {
        if (calander.projectSetting !== undefined) {
            setState({
                ...state,
                projectCalendarValues: calander.projectSetting.value.dates
            })
            setDays(calander.projectSetting.value.days.map((i) =>
                Number(i)
            ))
        }
    }, [calander])

    const handleProjectCalendarEditStart = () => {
        setState({ ...state, isProjectCalendarEdited: true })
    }

    const handleProjectCalendarSave = async (dates) => {
        const raw = {
            'key': 'calendar',
            'value': {
                dates,
                days: PROJECT_CALENDAR_DAYS
            }
        }
        Apis.updateCalender(raw).then((res) => {
            setState({
                ...state,
                isProjectCalendarEdited: false,
                projectCalendarValues: dates
            })
        }).catch((error) => {
            console.log(error)
        })

    }

    const handleProjectCalendarCancel = () => {
        setState({ ...state, isProjectCalendarEdited: false })
    }

    /* Function For Sink Values */
    const onTeamChange = (option, params) => {
        console.log(option)
        if (option === null) {
            if (params.name === 'team_id') {
                setValues({
                    ...values,
                    [params.name]: '',
                    ['team']: ''
                })
            } else if (params.name === 'member_id') {
                setValues({
                    ...values,
                    [params.name]: '',
                    ['member']: ''
                })
            }
        } else {
            if (params.name === 'team_id') {
                setValues({
                    ...values,
                    [params.name]: option.value,
                    ['team']: option
                })
            } else if (params.name === 'member_id') {
                setValues({
                    ...values,
                    [params.name]: option.value,
                    ['member']: option
                })
            }

        }


    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        if (name === 'team_name') {
            setValues({
                ...values,
                [name]: value,
                team_handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        } else if (name === 'team_abrv') {
            setValues({
                ...values,
                [name]: value.toUpperCase().replace(/ /g, '-')

            })
        } else if (name === 'first_name') {
            setValues({
                ...values,
                [name]: value,
                member_handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        } else if (name === 'member_abrv') {
            setValues({
                ...values,
                [name]: value.toUpperCase().replace(/ /g, '-')

            })
        } else if (name === 'email') {
            setValues({
                ...values,
                [name]: value.toLowerCase(),
            })
        }
        else {
            setValues({
                ...values,
                [name]: value,
            })
        }

    }
    /* Function get data of Team /Member/ Assositaion */
    const TeamList = () => {
        Apis.getTeam().then((res) => {
            setTeam(res.data.data)
            window.localStorage.setItem('team_List', JSON.stringify(res.data.data))
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    const MemberList = () => {
        Apis.getMember().then((res) => {
            setMember(res.data.data)
        }).catch((error) => {
            console.log(error)
        })

    }

    const AssociationList = () => {
        Apis.getAssociation().then((res) => {
            setAssociation(res.data.data)
        }).catch((error) => {
            console.log(error)
        })

    }
    /* Function For add and Update Team/Member/Associsation */
    const handleEditTeam = (team, tag, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        if (tag === 'team') {
            setValues({
                ...values,
                id: team._id,
                team_name: team.name,
                team_abrv: team.abrv,
                team_handle: team.handle,
                team_planning: team.planning,
                color: team.color
            })
        } else if (tag === 'member') {
            setValues({
                ...values,
                id: team._id,
                first_name: team.first_name,
                last_name: team.last_name,
                member_abrv: team.abrv,
                member_handle: team.handle,
                email: team.email.toLowerCase(),
                username: team.username
            })
        }


    }

    const handleTeamSubmit = (formdata, event) => {
        if (formdata.team_name === '') {
            setError({
                ...error,
                error: 'Team name is required',
                color: 'error',
                open: true
            })
        } else if (formdata.team_abrv === '') {
            setError({
                ...error,
                error: 'Team Abrv is required',
                color: 'error',
                open: true
            })
        } else if (formdata.team_handle === '') {
            setError({
                ...error,
                error: 'Team handle is required',
                color: 'error',
                open: true
            })
        } else if (formdata.team_planning === '') {
            setError({
                ...error,
                error: 'Team planning is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                const raw = {
                    'id': formdata.id,
                    'name': formdata.team_name,
                    'abrv': formdata.team_abrv,
                    'handle': formdata.team_handle,
                    'planning': formdata.team_planning,
                    'color': formdata.color
                }
                Apis.updateTeam(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        team_name: '',
                        team_abrv: '',
                        team_handle: '',
                        team_planning: '',
                        color: '#4D4D4D',
                    })
                    TeamList()
                    AssociationList()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            } else {
                const raw = {
                    'name': formdata.team_name,
                    'abrv': formdata.team_abrv,
                    'handle': formdata.team_handle,
                    'planning': formdata.team_planning,
                    'color': formdata.color
                }
                Apis.addTeam(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        team_name: '',
                        team_abrv: '',
                        team_handle: '',
                        team_planning: '',
                        color: '#4D4D4D',
                    })
                    TeamList()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }


    const handleMemberSubmit = (formdata, event) => {
        if (formdata.first_name === '') {
            setError({
                ...error,
                error: 'First name is required',
                color: 'error',
                open: true
            })
        } else if (formdata.last_name === '') {
            setError({
                ...error,
                error: 'Last name is required',
                color: 'error',
                open: true
            })
        } else if (formdata.member_abrv === '') {
            setError({
                ...error,
                error: 'Member Abrv is required',
                color: 'error',
                open: true
            })
        } else if (formdata.member_handle === '') {
            setError({
                ...error,
                error: 'Member handle is required',
                color: 'error',
                open: true
            })
        } else if (formdata.email === '') {
            setError({
                ...error,
                error: 'Member email is required',
                color: 'error',
                open: true
            })
        } else if (validator.isEmail(formdata.email) === false) {
            setError({
                ...error,
                error: 'Enter valid email!',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                const raw = {
                    'id': formdata.id,
                    'first_name': formdata.first_name,
                    'last_name': formdata.last_name,
                    'abrv': formdata.member_abrv,
                    'handle': formdata.member_handle,
                    'email': formdata.email.toLowerCase(),
                    'username': formdata.username
                }
                Apis.updateMember(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        first_name: '',
                        last_name: '',
                        member_abrv: '',
                        member_handle: '',
                        email: '',
                        username: ''
                    })
                    MemberList()
                    AssociationList()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            } else {
                const raw = {
                    'first_name': formdata.first_name,
                    'last_name': formdata.last_name,
                    'abrv': formdata.member_abrv,
                    'handle': formdata.member_handle,
                    'email': formdata.email.toLowerCase(),
                    'username': formdata.email
                }
                Apis.addMember(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        first_name: '',
                        last_name: '',
                        member_abrv: '',
                        member_handle: '',
                        email: '',
                        username: ''
                    })
                    MemberList()
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    const handleAssociationSubmit = (formdata, event) => {
        if (formdata.team_id === '') {
            setError({
                ...error,
                error: 'Team is required',
                color: 'error',
                open: true
            })
        } else if (formdata.member_id === '') {
            setError({
                ...error,
                error: 'Member is required',
                color: 'error',
                open: true
            })
        } else if (formdata.role === '') {
            setError({
                ...error,
                error: 'Role is required',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                'team_id': formdata.team_id,
                'member_id': formdata.member_id,
                'role': formdata.role
            }

            Apis.addAssociation(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setValues({
                    ...values,
                    team_id: '',
                    member_id: '',
                    role: ''
                })
                AssociationList()
            }).catch((error) => {
                setError({
                    ...error,
                    error: error.response.data.error,
                    color: 'error',
                    open: true
                })
            })
        }
    }

    /* Delete Functions */

    const handleDeleteTeam = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Something went wrong !',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        status: status
                    }
                    Apis.deleteTeam(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        TeamList()
                    }).catch((error) => {

                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        })
    }
    const handleForgetPassword = (email) => {
        const raw = {
            email: email
        }
        Apis.forgetUser(raw).then((res) => {
            setError({
                ...error,
                error: res.data.message,
                color: 'success',
                open: true
            })
        }).catch((error) => {
            setError({
                ...error,
                error: error.response.data.error,
                color: 'error',
                open: true
            })
        })
    }

    const handleDeleteMember = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Something went wrong !',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        status: status
                    }
                    Apis.deleteMember(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        MemberList()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        })
    }




    const handleDeleteAssociation = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Something went wrong !',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        status: status
                    }
                    Apis.deleteAssociation(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        AssociationList()
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        })
    }





    return {
        error,
        teamList,
        memberList,
        state,
        associationList,
        values,
        PROJECT_CALENDAR_DAYS,
        handleClick,
        handleAdd,
        handleColorChange,
        handleChange,
        onTeamChange,
        handleTeamSubmit,
        handleEditTeam,
        handleMemberSubmit,
        handleDeleteTeam,
        handleDeleteMember,
        handleDeleteAssociation,
        handleAssociationSubmit,
        handleProjectCalendarEditStart,
        handleProjectCalendarSave,
        handleProjectCalendarCancel,
        handleForgetPassword
    }
}

export default CollaboratorApi