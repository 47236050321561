import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import React, { useEffect } from 'react'
import Login from './pages/App/Login'
import ForgetPassword from './pages/App/ForgetPassword'
import ResetPassword from './pages/App/ResetPassword'
import EmailVerfication from './pages/App/EmailVerfication'
import Dashboard from './pages/Admin/Dashboard'
import PageNotFound from './pages/Error/PageNotFound'
import ReasonCode from './pages/Admin/ReasonCode'
import ProjectTags from './pages/Admin/ProjectTags'
import ChangePassword from './pages/Admin/Profile/ChangePassword'
import ChangeSystemPassword from './pages/Admin/Profile/ChangeSystemPassword'
import AddSubAdmin from './pages/Admin/Profile/AddSubAdmin'
import ProjectCollaborator from './pages/Admin/ProjectCollaborator'
import Projects from './pages/Admin/Projects'
import CostAnalyticsReports from './pages/Admin/CostAnalyticsReports'
import CostAttribute from './pages/Admin/CostAttribute'
import CostCodes from './pages/Admin/CostCodes'
import CostComponent from './pages/Admin/CostComponent'
import CostTrackMP from './pages/Admin/CostTrackMP'
import Analytics from './pages/Admin/Analytics'
import Setting from './pages/Admin/Profile/Setting'
import ProcessLibrary from './pages/Admin/ProcessLibrary'
import Cookies from 'js-cookie'
import Reports from './pages/Admin/Reports'
import CommitmentReports from './pages/Admin/Reports/components/CommitmentReports'
import LAP from './pages/Admin/LAP'
import CAP from './pages/Admin/CAP'
import swal from 'sweetalert'
import Program_Variance_Reports from './pages/Admin/Program_Variance_Reports'
import ConstraintsLog from './pages/Admin/ConstraintsLog'
import DisciplineReports from './pages/Admin/Reports/components/DisciplineReports'
import MaterialManagerSuppliers from './pages/Admin/MaterialManagerSuppliers'
import Material from './pages/Admin/MaterialManager/Material'
import Equipments from './pages/Admin/MaterialManager/Equipments'
import MaterialAttribute from './pages/Admin/MaterialManager/MaterialAttribute'
import MaterialEquipmentLogs from './pages/Admin/MaterialManager/MaterialEquipmentLogs'
import MaterialReports from './pages/Admin/Reports/components/MaterialReports'
import EquipmentReports from './pages/Admin/Reports/components/EquipmentReports'
/* import ProcessMapper from './pages/Admin/ProcessMapper' */



function App() {

  let isLogin = false
  let x = Cookies.get('login')
  if (x !== undefined) {
    isLogin = true
  } else {
    isLogin = false
  }


  const checkForInActivity = () => {
    const expireTime = localStorage.getItem("expireTime")
    if (expireTime < Date.now()) {
      swal({
        title: "Your session expired due to inactivity.",
        icon: "warning",
        // timer: 2000,
        // timerProgressBar: true,
        dangerMode: true,
        /* buttons: true, */
        buttons: ["Keep me logged in!", "ok"],
      }).then((logout) => {
        if (logout) {
          localStorage.removeItem('user-info')
          document.cookie = "login=false;expires=Thu, 01 Jan 1970 00:00:00 UTC;"
          window.location.replace('/')
        } else {
          const expireTime = Date.now() + 900000
          localStorage.setItem("expireTime", expireTime)
        }
      });
    }
  }


  const updateExpireTime = () => {
    const expireTime = Date.now() + 900000
    localStorage.setItem("expireTime", expireTime)
  }

  // Check for Inactivity
  useEffect(() => {
    if (isLogin === true) {
      const interval = setInterval(() => {
        checkForInActivity();
      }, 1000)
      return () => clearInterval(interval)
    }


  }, [isLogin])

  // Expired Time 
  useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime)
    window.addEventListener("keypress", updateExpireTime)
    window.addEventListener("mousemove", updateExpireTime)
    window.addEventListener("scroll", updateExpireTime)

    return () => {
      window.removeEventListener("click", updateExpireTime)
      window.removeEventListener("keypress", updateExpireTime)
      window.removeEventListener("mousemove", updateExpireTime)
      window.removeEventListener("scroll", updateExpireTime)
    }

  }, [])





  return (
    <>
      <Router>
        <Routes>
          {/* Login Routes */}
          <Route path="/" element={isLogin === false ? (<Login />) : (<Navigate to='/dashboard' replace />)} />
          <Route path="/forget-password" element={isLogin === false ? (<ForgetPassword />) : (<Navigate to='/dashboard' replace />)} />
          <Route path="/reset-password" element={isLogin === false ? (<ResetPassword />) : (<Navigate to='/dashboard' replace />)} />
          <Route path="/verify-email/:id/:token" element={isLogin === false ? (<EmailVerfication />) : (<Navigate to='/dashboard' replace />)} />

          {/* Admin Routes */}
          <Route path="/dashboard" element={isLogin === true ? (<Dashboard />) : (<Navigate to='/' replace />)} />
          <Route path="/change-password" element={isLogin === true ? (<ChangePassword />) : (<Navigate to='/' replace />)} />
          <Route path="/change-system-password" element={isLogin === true ? (<ChangeSystemPassword />) : (<Navigate to='/' replace />)} />
          <Route path="/manage-sub-admin" element={isLogin === true ? (<AddSubAdmin />) : (<Navigate to='/' replace />)} />
          <Route path="/system-settings" element={isLogin === true ? (<Setting />) : (<Navigate to='/' replace />)} />

          {/* Project Attributes Routes */}
          <Route path="/reason-codes" element={isLogin === true ? (<ReasonCode />) : (<Navigate to='/' replace />)} />
          <Route path="/project-tags" element={isLogin === true ? (<ProjectTags />) : (<Navigate to='/' replace />)} />
          <Route path="/project-collaborators" element={isLogin === true ? (<ProjectCollaborator />) : (<Navigate to='/' replace />)} />
          <Route path="/work-structure" element={isLogin === true ? (<Projects />) : (<Navigate to='/' replace />)} />

          {/* Cost Tracker */}
          <Route path="/cost-analytics-reports" element={isLogin === true ? (<CostAnalyticsReports />) : (<Navigate to='/' replace />)} />
          <Route path="/cost-attributes" element={isLogin === true ? (<CostAttribute />) : (<Navigate to='/' replace />)} />
          <Route path="/cost-codes" element={isLogin === true ? (<CostCodes />) : (<Navigate to='/' replace />)} />
          <Route path="/cost-components" element={isLogin === true ? (<CostComponent />) : (<Navigate to='/' replace />)} />
          <Route path="/cost-track-mp" element={isLogin === true ? (<CostTrackMP />) : (<Navigate to='/' replace />)} />

          {/* Production Control */}
          <Route path="/look-ahead-plan" element={isLogin === true ? (<LAP />) : (<Navigate to='/' replace />)} />
          <Route path="/commitment-plan" element={isLogin === true ? (<CAP />) : (<Navigate to='/' replace />)} />
          <Route path="/analytics" element={isLogin === true ? (<Analytics />) : (<Navigate to='/' replace />)} />
          <Route path="/reports" element={isLogin === true ? (<Reports />) : (<Navigate to='/' replace />)} />
          <Route path="/commitment-plan-reports" element={isLogin === true ? (<CommitmentReports />) : (<Navigate to='/' replace />)} />
          <Route path="/process-library" element={isLogin === true ? (<ProcessLibrary />) : (<Navigate to='/' replace />)} />
          <Route path="/reports/program_variance_reports" element={isLogin === true ? (<Program_Variance_Reports />) : (<Navigate to='/' replace />)} />
          <Route path="/reports/resource_histogram_reports" element={isLogin === true ? (<DisciplineReports />) : (<Navigate to='/' replace />)} />
          <Route path="/reports/material_reports" element={isLogin === true ? (<MaterialReports />) : (<Navigate to='/' replace />)} />
          <Route path="/reports/equipment_reports" element={isLogin === true ? (<EquipmentReports />) : (<Navigate to='/' replace />)} />
          <Route path="/constraints-log" element={isLogin === true ? (<ConstraintsLog />) : (<Navigate to='/' replace />)} />

          <Route path="*" element={<PageNotFound />} />

          {/* Material Manager Routes */}
          <Route path="/suppliers" element={isLogin === true ? (<MaterialManagerSuppliers />) : (<Navigate to='/' replace />)} />
          <Route path="/material" element={isLogin === true ? (<Material />) : (<Navigate to='/' replace />)} />
          <Route path="/equipments" element={isLogin === true ? (<Equipments />) : (<Navigate to='/' replace />)} />
          <Route path="/material-attribute" element={isLogin === true ? (<MaterialAttribute />) : (<Navigate to='/' replace />)} />
          <Route path="/material-equipment-logs" element={isLogin === true ? (<MaterialEquipmentLogs />) : (<Navigate to='/' replace />)} />

          {/* Process Mapper */}
          {/* <Route path="/process-mapper" element={isLogin === true ? (<ProcessMapper />) : (<Navigate to='/' replace />)} /> */}
        </Routes>
      </Router >
    </>
  )
}

export default App;
