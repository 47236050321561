import React from 'react'
import Auth from './Auth'
import Alert from '../../../helpers/Alert'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Apis from '../../../api'
import config from '../../../config'

function Setting() {
    const { values, error, handleChange, handleUpadteSystemInfo, getSystemInfo, setError } = Auth()
    const [loginImage, setLoginImage] = React.useState({
        id: '',
        button_name: 'Add',
        BackgroundImage: './assets/images/login-background.jpg',
        show_text: true,
        show_text_id: '',

    })

    const [refresh, setRefresh] = React.useState(false);
    React.useEffect(() => { getSystemInfo('project_name', values) }, [values.id])
    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const loginImageSetting = data.find(setting => setting.key === 'login_image');
                const loginImageTextShow = data.find(setting => setting.key === 'show_login_image_text');
                if (loginImageSetting) {
                    setLoginImage({
                        ...loginImageSetting,
                        id: loginImageSetting._id,
                        button_name: 'Update',
                        BackgroundImage: config.api_url + '/api/login-image/view/' + loginImageSetting._id + '?refresh=' + refresh,
                        show_text_id: loginImageTextShow ? loginImageTextShow._id : '',
                        show_text: loginImageTextShow ? loginImageTextShow.value : true
                    })
                }

            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, [refresh]);

    React.useEffect(() => {
        if (loginImage.show_text_id !== '') {
            const raw = {
                'key': 'show_login_image_text',
                'value': loginImage.show_text ? true : false
            }
            Apis.updateCalender(raw).then((res) => {

            }).catch((error) => {
                console.log(error)
            });
        } else {
            const raw = {
                'key': 'show_login_image_text',
                'value': loginImage.show_text ? true : false
            }
            Apis.addSetting(raw).then((res) => {
                setRefresh(!refresh)

            }).catch((error) => {
                console.log(error)
            });
        }
    }, [loginImage.show_text])
    const handleAddOrUpdateImage = () => {

        const formData = new FormData();
        if (loginImage.id !== '') {
            if (values.files) {
                formData.append('id', loginImage.id);
                formData.append('files', values.files);
                Apis.updateLogingImage(formData).then((res) => {
                    setError({
                        ...error,
                        error: 'Image Uloaded',
                        color: 'success',
                        open: true
                    })
                    setRefresh(!refresh)

                }).catch((error) => {
                    setError({
                        ...error,
                        error: 'Login Image is to Large. Login Image size is less then 1 Mb',
                        color: 'error',
                        open: true
                    })
                });
            } else {
                setError({
                    ...error,
                    error: 'Please Select The File',
                    color: 'error',
                    open: true
                })
            }

        } else {
            if (values.files) {
                formData.append('key', 'login_image');
                formData.append('files', values.files);
                Apis.addLogingImage(formData).then((res) => {
                    setError({
                        ...error,
                        error: 'Image Uloaded',
                        color: 'success',
                        open: true
                    })
                    setRefresh(!refresh)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: 'Login Image is to Large. Login Image size is less then 1 Mb',
                        color: 'error',
                        open: true
                    })
                });
            } else {
                setError({
                    ...error,
                    error: 'Please Select The File',
                    color: 'error',
                    open: true
                })
            }
        }


    };
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Change Project Name| P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='' show=''></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Change Project Name</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Profile</li>
                                            <li className="breadcrumb-item active">Change Project Name</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-6">
                                            <div className=" shadow-none card">
                                                <div className='card-block'>
                                                    <div className="account-box">
                                                        <div className="card-box shadow-none p-4">
                                                            <div className="p-4">

                                                                <div className="form-group mb-3">
                                                                    <label for="projectName" className="form-label">Project Name</label>
                                                                    <input type="text" className="form-control" name='project_name' value={values.project_name} id="project_name" placeholder='Enter Project Name' onChange={handleChange} />
                                                                </div>
                                                                <div className="form-group row">
                                                                    <div className="col-12 text-end">
                                                                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => handleUpadteSystemInfo(values)} >Update</button>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className=" shadow-none card">
                                                <div className='card-block'>
                                                    <div className="account-box">
                                                        <div className="card-box shadow-none p-4">
                                                            <div className="p-4">

                                                                <div className="form-group mb-3">
                                                                    <label for="projectName" className="form-label">Login page Image</label><span> (Dim.1600X1000, Size less then 1 MB)</span>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name="files"
                                                                        id="fileInput"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        onChange={(e) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'files',
                                                                                    value: e.target.files[0],
                                                                                    type: 'file',
                                                                                },
                                                                            });
                                                                        }} />

                                                                </div>
                                                                <div className="form-check mb-3">
                                                                    <input type="checkbox" className="form-check-input" name='show_login_page_text' id="show_login_page_text" onChange={() => {
                                                                        setLoginImage({
                                                                            ...loginImage,
                                                                            show_text: !loginImage.show_text
                                                                        })
                                                                    }} checked={loginImage.show_text} />
                                                                    <label htmlFor="show_login_page_text" className="form-check-label">Show Login Page Text</label>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <div className="col-12 text-end">
                                                                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={handleAddOrUpdateImage}>{loginImage.button_name}</button>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="p-4 einsteen_image">
                                                                <label htmlFor="show_login_page_text" className="form-check-label">Current Login Page Image</label>
                                                                <img src={loginImage.BackgroundImage} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default Setting
