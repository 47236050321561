import React from 'react'
import Apis from '../../api'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import axios from 'axios'

const Auth = (callback) => {
    const navigate = useNavigate()
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        isChecked: false,
        ip_address: ''
    })

    const [error, setError] = React.useState([])
    const [color, setColor] = React.useState([])
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {

        axios.get('https://api.ipify.org/?format=json"').then((res) => {
            if (localStorage.checkbox && localStorage.email !== "") {
                setValues({
                    ...values,
                    isChecked: true,
                    email: localStorage.email,
                    password: localStorage.password,
                    ip_address: res.data
                });
            } else {
                setValues({
                    ...values,
                    ['ip_address']: res.data
                })
            }


        }).catch((error) => {
            console.log(error)
        })

    }, [])

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        if (name === 'lsRememberMe') {
            setValues({
                ...values,
                ['isChecked']: event.target.checked
            })
        } else if (name === 'email') {
            setValues({
                ...values,
                [name]: value.toLowerCase()
            })
        }
        else {

            setValues({
                ...values,
                [name]: value
            })
        }


    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const handleLogin = (event) => {
        event.preventDefault()
        const formdata = values
        if (formdata.email === '') {
            setError('Username is required')
            setColor('error')
            setOpen(true)
        } else if (formdata.password === '') {
            setError('Password is required')
            setColor('error')
            setOpen(true)
        } else {
            const raw = {
                email: formdata.email,
                password: formdata.password,
                isChecked: formdata.isChecked,
                login_date: new Date().getTime(),
                ipAddress: formdata.ip_address
            }


            if (formdata.isChecked && formdata.email !== "") {
                localStorage.email = formdata.email;
                localStorage.password = formdata.password;
                localStorage.checkbox = formdata.isChecked;
            }
            Apis.userlogin(raw).then((res) => {

                window.localStorage.setItem('user-info', JSON.stringify(res.data.data))
                Cookies.set('login', true);
                window.location.replace('/dashboard')

            }).catch((error) => {
                setError(error.response.data.error)
                setColor('error')
                setOpen(true)
            })
        }
    }

    const handleForgetPassword = (event) => {
        event.preventDefault()
        const formdata = {
            email: values.email
        }
        if (formdata.email === '') {
            setError('Username is required')
            setColor('error')
            setOpen(true)
        } else {
            Apis.forgetUser(formdata).then((res) => {
                setError(res.data.message)
                setColor('success')
                setOpen(true)
            }).catch((error) => {
                setError(error.response.data.error)
                setColor('error')
                setOpen(true)
            })

        }

    }


    const handleResetPassword = (event) => {
        event.preventDefault()
        const formdata = {
            password: values.password,
            confirmpassword: values.confirmpassword
        }
        if (formdata.password === '') {
            setError('Password is required')
            setColor('error')
            setOpen(true)
        } else if (formdata.confirmpassword === '' || formdata.confirmpassword === undefined || formdata.confirmpassword !== formdata.password) {
            setError('Mismatch Password!')
            setColor('error')
            setOpen(true)
        } else {
            const token = JSON.parse(window.localStorage.getItem('user-token'))
            const raw = {
                id: token.id,
                password: formdata.password
            }
            Apis.resetPassword(raw).then((res) => {
                setError(res.data.message)
                setColor('success')
                setOpen(true)
                window.localStorage.clear()
                setTimeout(() => {
                    navigate('/')
                }, 3000)

            }).catch((error) => {
                setError(error.response.data.message)
                setColor('error')
                setOpen(true)
            })
        }


    }
    return {
        values,
        error,
        color,
        open,
        handleChange,
        handleClose,
        handleLogin,
        handleForgetPassword,
        handleResetPassword
    }
}

export default Auth
