import React, { useEffect } from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Board from 'react-trello'
import { MovableCardWrapper } from 'react-trello/dist/styles/Base'
import Modal from 'react-bootstrap/Modal'
import { default as ReactSelect } from "react-select"
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import AttributeApiCall from '../ProjectTags/AttributeApiCall'
import Alert from '../../../helpers/Alert'
import Apis from '../../../api'

const ConstraintCard = ({
    onClick,
    onChange,
    id,
    constraint,
    email,
    team,
    work_package,
    check_list,
    checked_list,
    comments,
    status,
}) => {
    const onUpdateEvent = (id, checked_list) => {
        onChange({ id: id, checked_list: checked_list })
    }

    return (
        <MovableCardWrapper>
            <header
                style={{
                    borderBottom: '1px solid #eee',
                    paddingBottom: 6,
                    marginBottom: 10,
                    display: 'flex',
                    color: '#111',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ fontSize: 14, fontWeight: 'bold' }}>{constraint}</div>
                <i className={'fas fa-edit'} onClick={onClick} />
            </header>
            <div style={{ padding: '5px 0px', color: '#111' }}>{email}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>Team: {team}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>Work Package: {work_package}</div>
            <div style={{ padding: '5px 0px', color: '#111' }}>
                {check_list.map((item, index) => (
                    <div className="custom-control custom-checkbox" key={index}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={'checkbox' + id + '_' + index}
                            value={item}
                            defaultChecked={checked_list.includes(item)}
                            onClick={(e) => {
                                if (checked_list.includes(item)) {
                                    let idx = checked_list.indexOf(item)
                                    if (idx > -1) {
                                        checked_list.splice(idx, 1)
                                        onUpdateEvent(id, checked_list)
                                    }
                                } else {
                                    checked_list.push(item)
                                    onUpdateEvent(id, checked_list)
                                }
                            }}
                        />
                        <label className="custom-control-label" htmlFor={'checkbox' + id + '_' + index}>
                            {item}
                        </label>
                    </div>
                ))}
            </div>
            <div style={{ padding: '5px 0px', color: '#111' }}>{comments.length}</div>
        </MovableCardWrapper>
    )
}

const findTeamName = (id, teams) => {
    for (var i = 0; i < teams.length; i++) {
        var el = teams[i];
        if (el._id === id) {
            return i
        }
    }
    return -1
}

const findpackageIndex = (id, packages) => {
    for (var i = 0; i < packages.length; i++) {
        var el = packages[i];
        if (el.value === id) {
            return i
        }
    }
    return -1
}



function ConstraintsLog() {
    const [data, setData] = React.useState({ boardData: { lanes: [{ id: 'loading', title: 'loading..', cards: [] }] } })
    const [state, setState] = React.useState({
        eventBus: undefined,
        selected_card: undefined,
        comment_request: false,
        checklist_request: false,
    })
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [add_show, setAddShow] = React.useState(false)
    const [editConstraintModal, seteditConstraintModal] = React.useState(false)
    const [teams, setTeams] = React.useState([])
    const [workPackages, setworkPackages] = React.useState([])
    const { teamList } = CollaboratorApi()
    const { attributes } = AttributeApiCall()
    const [all_constraints, setAllConstraints] = React.useState([])
    const [all_constraints_comments, setAllConstraintsComments] = React.useState([])
    const [refresh, setRefresh] = React.useState(false)
    const [formData, setFormData] = React.useState({
        intitiated_by: user.id,
        constraint: '',
        team: [],
        workPackages: [],
        checklist: '',
        user_email: user.email,
        user_name: user.name,
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    useEffect(() => {
        setFormData({
            ...formData,
            constraint: '',
            team: [],
            workPackages: [],
            checklist: '',
            add_comment_text: '',
            id: ''
        })
    }, [add_show])
    useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])

    React.useEffect(() => {
        let demo
        let list = []
        if (attributes.length > 0) {
            attributes.projectattribute.map((elm) => {
                if (elm.attribute_name === "Work Package") {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    list.push(demo)
                }
                return list
            })
            setworkPackages(list)
        }
    }, [attributes])

    useEffect(() => {
        Apis.getConstraint().then((res) => {
            setAllConstraints(res.data.data.constraint)
            setAllConstraintsComments(res.data.data.comments)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh])

    useEffect(() => {
        let lane1 = []
        let lane2 = []
        let lane3 = []
        let lane4 = []
        if (all_constraints.length !== 0 && workPackages.length !== 0 && teams.length !== 0) {
            all_constraints.forEach((item) => {
                let teamIndex = findTeamName(item.team, teams)
                let packageIndex = findpackageIndex(item.work_packages, workPackages)
                let demo = []
                all_constraints_comments.map((comment) => {
                    if (comment.constraint_id === item._id) {
                        demo.push(comment)
                    }
                    return demo
                })
                let data = {
                    id: item._id,
                    constraint: item.constraint,
                    email: item.user_email,
                    team: teams[teamIndex].name,
                    work_package: workPackages[packageIndex].label,
                    check_list: item.check_list.split(','),
                    checked_list: item.checked_list === null ? [] : item.checked_list.split(','),
                    comments: demo,
                    status: item.status,
                    metadata: { id: item._id },
                }
                switch (item.status) {
                    case 1:
                        lane1.push(data)
                        break
                    case 2:
                        lane2.push(data)
                        break
                    case 3:
                        lane3.push(data)
                        break
                    case 4:
                        lane4.push(data)
                        break
                    default:
                        lane1.push(data)
                        break
                }
            })
        }

        let boardData = {
            lanes: [
                {
                    id: 'constraint',
                    title: 'Constraint',
                    label: lane1.length.toString(),
                    currentPage: 1,
                    cards: lane1,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
                {
                    id: 'work_in_progress',
                    title: 'Work In Progress',
                    label: lane2.length.toString(),
                    currentPage: 1,
                    cards: lane2,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
                {
                    id: 'blocked',
                    title: 'Blocked',
                    label: lane3.length.toString(),
                    currentPage: 1,
                    cards: lane3,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
                {
                    id: 'completed',
                    title: 'Completed',
                    label: lane4.length.toString(),
                    currentPage: 1,
                    cards: lane4,
                    style: { color: 'white', width: '24%', minWidth: '270px' },
                },
            ],
        }
        setData({ boardData })
    }, [all_constraints, all_constraints_comments, workPackages, teams])



    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setFormData({
            ...formData,
            [name]: value,
        })
        setError({
            ...error,
            open: false
        })

    }
    const onTeamChange = (option, params) => {
        setFormData({
            ...formData,
            [params.name]: params.action === 'clear' ? [] : option,
        })
        setError({
            ...error,
            open: false
        })

    }

    const cardEditHandler = (laneId, cardId) => {
        let targetLane = data.boardData.lanes.find((item) => item.id === laneId)
        if (targetLane === undefined) return
        let targetCard = targetLane.cards.find((item) => item.id === cardId)
        setState({
            ...state,
            selected_card: targetCard,
        })
        setFormData({
            ...formData,
            constraint: targetCard.constraint,
            id: targetCard.id
        })
        seteditConstraintModal(!editConstraintModal)


    }
    const checkboxUpdateHandler = (card_id, card) => {
        let current_checked = card.checked_list.filter((item) => item !== undefined && item !== '')

        let raw = {
            id: card.id,
            checked_list: current_checked.toString() ? current_checked.toString() : null
        }
        Apis.updateConstraintCheckedList(raw).then((res) => {
            setRefresh(!refresh)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getStatusValue = (value) => {
        switch (value) {
            case 'constraint':
                return 1
            case 'work_in_progress':
                return 2
            case 'blocked':
                return 3
            case 'completed':
                return 4
            default:
                return 1
        }
    }

    const onCardMoveAcrossLanes = async (fromLaneId, toLaneId, cardId, addedIndex) => {
        let targetID = getStatusValue(toLaneId)
        console.log(targetID)
        let raw = {
            id: cardId,
            status: targetID,
            updatedAt: new Date().getTime()
        }
        Apis.updateConstraintsPosition(raw).then((res) => {
            setRefresh(!refresh)
        }).catch((error) => {
            console.log(error)
        })
    }

    const setEventBus = (eventBus) => {
        setState({
            ...state,
            eventBus
        })
    }
    const handleSave = (data) => {

        if (data.constraint === "") {
            setError({
                ...error,
                error: 'Constraint is required',
                color: 'error',
                open: true
            })
        } else if (data.team.length === 0) {
            setError({
                ...error,
                error: 'Team is required',
                color: 'error',
                open: true
            })

        } else if (data.workPackages.length === 0) {
            setError({
                ...error,
                error: 'Work Packages is required',
                color: 'error',
                open: true
            })
        } else if (data.checklist === "") {
            setError({
                ...error,
                error: 'Checklist is required',
                color: 'error',
                open: true
            })
        } else {
            setError({
                ...error,
                open: false
            })

            let raw = {
                constraint: data.constraint,
                intitiated_by: data.intitiated_by,
                team: data.team.value,
                work_packages: data.workPackages.value,
                check_list: data.checklist,
                user_email: data.user_email,
                createdAt: new Date().getTime(),
                updatedAt: new Date().getTime()
            }
            Apis.addConstraint(raw).then((res) => {
                setAddShow(!add_show)
                setRefresh(!refresh)
                setFormData({
                    ...formData,
                    constraint: '',
                    team: [],
                    workPackages: [],
                    checklist: '',
                    add_comment_text: '',
                    id: ''
                })

            }).catch((error) => {
                console.log(error)
            })
        }
    }
    const handleUpdate = (data) => {
        if (data.constraint === "") {
            setError({
                ...error,
                error: 'Constraint is required',
                color: 'error',
                open: true
            })

        } else {
            setError({
                ...error,
                open: false
            })
            let raw = {
                constraint: data.constraint,
                id: data.id,
                updatedAt: new Date().getTime()
            }
            Apis.updateConstraint(raw).then((res) => {
                seteditConstraintModal(!editConstraintModal)
                setRefresh(!refresh)
                setFormData({
                    ...formData,
                    constraint: '',
                    team: [],
                    workPackages: [],
                    checklist: '',
                    id: '',
                    add_comment_text: ''
                })

            }).catch((error) => {
                console.log(error)
            })
        }
    }
    const addCheckList = () => {
        if (state.selected_card === undefined) return
        if (state.checklist_request) return
        let check = formData.checklist
        if (check.length) {
            let current_checkList = state.selected_card.check_list
            current_checkList.push(check)
            setState({
                ...state,
                checklist_request: true,
            })
            let raw = {
                check_list: current_checkList.toString(),
                id: state.selected_card.id
            }
            Apis.updateConstraintsChecklist(raw).then((res) => {
                setFormData({
                    ...formData,
                    checklist: '',
                })
                setRefresh(!refresh)
                const selected_card = state.selected_card
                selected_card.check_list = current_checkList
                setState({
                    ...state,
                    checklist_request: false,
                    selected_card,
                })
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const addComment = () => {
        if (state.selected_card === undefined) return
        if (state.comment_request) return
        let comment = formData.add_comment_text
        if (comment.length) {
            setState({
                ...state,
                comment_request: true,
            })
            let raw = {
                user_id: formData.intitiated_by,
                constraint_id: state.selected_card.id,
                content: formData.add_comment_text,
                user_name: formData.user_name,
                createdAt: new Date().getTime(),
                updatedAt: new Date().getTime()
            }
            let commentsList = state.selected_card.comments
            commentsList.push(raw)
            Apis.addConstraintComment(raw).then((res) => {
                setFormData({
                    ...formData,
                    add_comment_text: '',
                })
                const selected_card = state.selected_card
                selected_card.comments = commentsList
                setState({
                    ...state,
                    comment_request: false,
                    selected_card,
                })
            }).catch((error) => {
                console.log(error)
            })

        }
    }
    return (
        <>
            <Header title='Constraints-log | P2IC'></Header>
            <Sidebar title='Product_control' show='constraintslog'></Sidebar>
            <Alert error={error}></Alert>
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <h6 className="page-title">Constraints Log</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item ">Production Control</li>
                                            <li className="breadcrumb-item active">Constraints Log</li>
                                        </ol>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="text-end d-none d-md-block">
                                            <button class="btn btn-primary waves-effect waves-light btn btn-primary" onClick={() => setAddShow(!add_show)}>Add Constraint</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-projext-sec ps-2" >
                                <Board
                                    data={data.boardData}
                                    draggable
                                    cardDragClass="draggingCard"
                                    laneDraggable={false}
                                    className="boardContainer"
                                    components={{ Card: ConstraintCard }}
                                    onCardClick={(laneId, metadata, cardId) => cardEditHandler(cardId, laneId)}
                                    onCardUpdate={(cardId, card) => checkboxUpdateHandler(cardId, card)}
                                    onCardMoveAcrossLanes={onCardMoveAcrossLanes}
                                    eventBusHandle={setEventBus}
                                    style={{ backgroundColor: '#404040', justifyContent: 'center' }}
                                />
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={add_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setAddShow(!add_show)} centered>
                <Modal.Header style={{ gap: '15px' }} closeButton>
                    <Modal.Title>Add Constraint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="constraint" className="col-form-label">Constraint</label>
                                <input type="text" name='constraint' id="constraint" className="form-control" value={formData.constraint} onChange={handleChange} placeholder='Enter Constraint' />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="team" className="col-form-label">Team</label>
                                <ReactSelect
                                    options={teams.map((i) => ({ value: i._id, label: i.name }))}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    name="team"
                                    isClearable
                                    onChange={onTeamChange}
                                    value={formData.team}


                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="workPackages" className="col-form-label">Work Packages</label>
                                <ReactSelect
                                    options={workPackages}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    name="workPackages"
                                    isClearable
                                    onChange={onTeamChange}
                                    value={formData.workPackages}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="checklist" className="col-form-label">Checklist</label>
                                <input type="text" name='checklist' id="checklist" className="form-control" value={formData.checklist} onChange={handleChange} placeholder='Enter Constraint' />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSave(formData)}>Add</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setAddShow(!add_show)} >Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={editConstraintModal} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => seteditConstraintModal(!editConstraintModal)} centered>

                <Modal.Header style={{ gap: '15px' }} closeButton>
                    <Modal.Title>Edit Constraint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.selected_card !== undefined ? (<>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="constraint" className="col-form-label">Constraint</label>
                                    <input type="text" name='constraint' id="constraint" className="form-control" value={formData.constraint} onChange={handleChange} placeholder='Enter Constraint' />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <label htmlFor="checklist" className="col-form-label">Checklist</label>
                                {state.selected_card.check_list.map((item, index) => (
                                    <div className="custom-control custom-checkbox" key={index}>
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={'edit_checkbox' + state.selected_card.id + '_' + index}
                                            defaultChecked={state.selected_card.checked_list.includes(item)}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={'edit_checkbox' + state.selected_card.id + '_' + index}
                                        >
                                            {item}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="col-lg-10">
                                <div className="mb-3">
                                    <input type="text" name='checklist' id="checklist" className="form-control" value={formData.checklist} onChange={handleChange} placeholder='Enter Constraint' />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="mb-3">
                                    <button className="btn-block inner btn btn-primary" style={{ width: '100%' }} onClick={addCheckList}>Add</button>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="mb-3">
                                    <label htmlFor="add_comment_text" className="col-form-label">Add Comment:</label>
                                    <input type="text" name='add_comment_text' id="add_comment_text" className="form-control" value={formData.add_comment_text} onChange={handleChange} placeholder='Enter Constraint' />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="mt-3">
                                    <button className="btn-block inner btn btn-primary" style={{ width: '100%' }} onClick={addComment} >Add</button>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <label className="col-form-label">Comments:</label>
                                {state.selected_card.comments.map((item, index) => (
                                    <div key={index} className="mb-2">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <i className="mdi mdi-account-circle font-size-24 align-middle mr-1" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <h4 className="font-size-15 m-0">{item.user_name}</h4>
                                                <small className="text-muted">{item.content}</small>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </>) : (<><div className="mt-4 text-center">Loading</div></>)}

                </Modal.Body >
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleUpdate(formData)} >Update</button>
                    <button type="button" className="btn btn-secondary" onClick={() => seteditConstraintModal(!editConstraintModal)} >Close</button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default ConstraintsLog