import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Alert from '../../../helpers/Alert'
import ProjectApi from './ProjectApi'
import { components } from "react-select"
import { default as ReactSelect } from "react-select"

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

function Projects() {

    const {
        error,
        values,
        projectList,
        planList,
        work_package_list,
        handleChange,
        handleCheckSystemPassword,
        handleProjectSubmit,
        EditProject,
        CancelEditProject,
        LockProject,
        ShowPlaneList,
        handleSelectChange,
        handlePlanSubmit,
        EditPlans,
        LockPlane } = ProjectApi()



    const ProjectList = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.projects.map((elm) => {
                if (elm.is_locked === true) {
                    return (<><div className="accordion-item">
                        <h2 className="accordion-header" id={"headingOne" + elm._id}>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + elm._id} aria-expanded="false" aria-controls={"#collapse_" + elm._id} onClick={() => ShowPlaneList(elm)}>
                                <i className="fas fa-folder"></i> {elm.name} <i className="fas fa-lock"></i>
                            </button>
                        </h2>
                        <div id={"collapse_" + elm._id} className="accordion-collapse collapse" aria-labelledby={"headingOne" + elm._id} data-bs-parent="#accordionExample">
                            <div className="accordion-body ">
                                <button className="btn-primary btn waves-effect waves-light btn btn-primary" data-bs-target="#confirmPassword" data-bs-toggle="modal" type="button" onClick={() => LockProject(elm)}>Unlock</button>
                            </div>
                        </div>
                    </div></>)
                } else {
                    return (<>
                        <div className="accordion-item" >
                            <h2 className="accordion-header" id={"headingOne" + elm._id}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + elm._id} aria-expanded="false" aria-controls={"#collapse_" + elm._id} onClick={() => ShowPlaneList(elm)}>
                                    <i className="fas fa-folder"></i> {elm.name}
                                </button>
                            </h2>
                            <div id={"collapse_" + elm._id} className="accordion-collapse collapse" aria-labelledby={"headingOne" + elm._id} data-bs-parent="#accordionExample">
                                <div className="accordion-body ">
                                    <div style={values.edit_card === 0 ? { display: 'block' } : { display: 'none' }}>
                                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => EditProject(elm)}>Edit</button>
                                        <button className="btn btn-outline-dark w-md waves-effect waves-light mx-2" data-bs-target="#confirmPassword" data-bs-toggle="modal" type="button" onClick={() => LockProject(elm)}>Lock</button>
                                        <div className="accordion" id={"accordionExample1" + elm._id}>
                                            {PlanLists(planList, elm._id)}
                                            <div className="child_projectbtn"><button type="button"
                                                className="btn btn-secondary" data-bs-target="#newSubPlan" data-bs-toggle="modal" style={values.edit_plan_card === 0 ? { display: 'block' } : { display: 'none' }}>Add a new Phase
                                                Plan</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card border border-1" style={values.edit_card === 1 ? { width: "20rem", display: 'block' } : { width: "20rem", display: 'none' }}>
                                        <div className="card-body ">
                                            <div className='container'>
                                                <form>
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="project-name" className="col-form-label">Sub Project</label>
                                                                <input type="text" id='project-name' name='project_name' value={values.project_name} onChange={handleChange} className="form-control" placeholder='Enter Project Name' />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="project-description" className="col-form-label">Description</label>
                                                                <textarea id='project-description' name='project_description' rows="3" value={values.project_description} onChange={handleChange} className="form-control" placeholder='Enter Description' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="btn-container mb-2 text-end">
                                                <div className="mb-2">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => handleProjectSubmit(values)}>Save</button>
                                                    <button className="btn btn-outline-dark w-md waves-effect waves-light mx-2" onClick={() => CancelEditProject()}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>)
                }
            }))
        }
        return list
    }



    const PlanLists = (lists, id) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.plans.map((elm) => {
                if (elm.is_locked === true) {
                    return (<><div className="accordion-item">
                        <h2 className="accordion-header" id={"headingOne" + elm._id} >
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + elm._id} aria-expanded="false" aria-controls={"collapseOne" + elm._id} onClick={() => CancelEditProject()}>
                                <i className="fas fa-folder"></i>{elm.name}<i className="fas fa-lock"></i>
                            </button>
                        </h2>
                        <div id={"collapseOne" + elm._id} className="accordion-collapse collapse" aria-labelledby={"headingOne" + elm._id} data-bs-parent={"#accordionExample1" + id}>
                            <div className="accordion-body ">
                                <button className="btn btn-primary " data-bs-target="#confirmPassword" data-bs-toggle="modal" type="button" onClick={() => LockPlane(elm)}>Unlock</button>

                            </div>
                        </div>
                    </div></>)
                } else {
                    return (<>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={"headingOne" + elm._id} >
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + elm._id} aria-expanded="false" aria-controls={"collapseOne" + elm._id} onClick={() => CancelEditProject()}>
                                    <i className="fas fa-folder"></i>{elm.name}
                                </button>
                            </h2>
                            <div id={"collapseOne" + elm._id} className="accordion-collapse collapse" aria-labelledby={"headingOne" + elm._id} data-bs-parent={"#accordionExample1" + id}>
                                <div className="accordion-body ">
                                    <div style={values.edit_plan_card === 0 ? { display: 'block' } : { display: 'none' }}>
                                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => EditPlans(elm)} >Edit</button>
                                        <button className="btn btn-outline-dark w-md waves-effect waves-light mx-2" data-bs-target="#confirmPassword" data-bs-toggle="modal" type="button" onClick={() => LockPlane(elm)}>Lock</button>
                                    </div>
                                    <div className="card border border-1" style={values.edit_plan_card === 1 ? { width: "21rem", display: 'block' } : { width: "21rem", display: 'none' }}>
                                        <div className="card-body ">
                                            <div className='container'>
                                                <form>
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="plan-name" className="col-form-label">Phase Plan</label>
                                                                <input type="text" id='plan-name' name='plan_name' value={values.plan_name} onChange={handleChange} className="form-control" placeholder='Enter Plan Name' />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="plan-description" className="col-form-label">Description</label>
                                                                <textarea id='plan-description' name='plan_description' rows="3" value={values.plan_description} onChange={handleChange} className="form-control" placeholder='Enter Description' />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="plan-description" className="col-form-label">Work Packages</label>
                                                                <ReactSelect
                                                                    options={work_package_list}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    hideSelectedOptions={false}
                                                                    components={{
                                                                        Option
                                                                    }}
                                                                    onChange={handleSelectChange}
                                                                    allowSelectAll={true}
                                                                    value={values.work_packages}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="btn-container mb-2 text-end">
                                                <div className="mb-2">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => handlePlanSubmit(values)} >Save</button>
                                                    <button className="btn btn-outline-dark w-md waves-effect waves-light mx-2" type="button" onClick={() => CancelEditProject()}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)
                }
            }))
        }
        return list
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='New Make Ready plan | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='workStructure'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Work Structure</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Project Attributes</li>
                                            <li className="breadcrumb-item active">Work Structure</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub-projext-sec ps-5">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <h2>Sub Project</h2>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <button type="button" className="btn btn-secondary" data-bs-target="#confirmPassword" data-bs-toggle="modal" style={!values.system_password_status ? { display: 'block' } : { display: 'none' }}>Add a new Sub Project</button>
                                            <button type="button" data-bs-target="#newSubProject" className="btn btn-primary" data-bs-toggle="modal" style={values.system_password_status ? { display: 'block' } : { display: 'none' }}>Add a new Sub Project</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="right_maintab">
                                                <div className="accordion" id="accordionExample">
                                                    {ProjectList(projectList)}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            {/* Confirm Password Model */}
            <div id="confirmPassword" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm System Password</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <input type="password" name='system_password' value={values.system_password} onChange={handleChange} className="form-control" placeholder='Enter system password' />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleCheckSystemPassword(values)}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add Project Model */}
            <div id="newSubProject" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">New Project</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="project-name" className="col-form-label">Sub Project</label>
                                            <input type="text" id='project-name' name='project_name' value={values.project_name} onChange={handleChange} className="form-control" placeholder='Enter Project Name' />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="project-description" className="col-form-label">Description</label>
                                            <textarea id='project-description' name='project_description' rows="3" value={values.project_description} onChange={handleChange} className="form-control" placeholder='Enter Description' />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleProjectSubmit(values)}>Save</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add Plan Model */}
            <div id="newSubPlan" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered location_modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">New Plan</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="plan-name" className="col-form-label">Phase Plan</label>
                                            <input type="text" id='plan-name' name='plan_name' value={values.plan_name} onChange={handleChange} className="form-control" placeholder='Enter Plan Name' />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="plan-description" className="col-form-label">Description</label>
                                            <textarea id='plan-description' name='plan_description' rows="3" value={values.plan_description} onChange={handleChange} className="form-control" placeholder='Enter Description' />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="plan-description" className="col-form-label">Work Packages</label>
                                            <ReactSelect
                                                options={work_package_list}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={handleSelectChange}
                                                allowSelectAll={true}
                                                value={values.work_packages}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handlePlanSubmit(values)}>Save</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects