import React, { Suspense } from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import GanttChart from './healper/GanttChart'
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import ProjectApi from '../Projects/ProjectApi'
import Apis from '../../../api'
import AttributeApiCall from '../ProjectTags/AttributeApiCall'
import LapToolbar from './healper/LapToolbar'
const status_codes = [
    {
        value: 1,
        label: 'All'
    }, {
        value: 2,
        label: 'Work in Process'
    },
    {
        value: 3,
        label: 'Work Completed'
    }
]

function LAP() {
    const [resourcesShow, setResourceShow] = React.useState(false);
    const [teams, setTeams] = React.useState([])
    const [members, setMembers] = React.useState([])
    const [associations, setAssociation] = React.useState([])
    const [projectLists, setProjectLists] = React.useState([])
    const [planList, setPlanList] = React.useState([])
    const [workList, setWorkList] = React.useState([])
    const [resources, setResources] = React.useState([])
    const { teamList, associationList, memberList, state } = CollaboratorApi()
    const { projectList } = ProjectApi()
    const { attributes } = AttributeApiCall()
    const [toolbarState, setToolbarState] = React.useState({
        status_code: { value: 2, label: "Work in Process" }
    })
    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }

    const handleShow = (value) => {
        setResourceShow(value)
    }

    /**********Filters*********/
    React.useEffect(() => {
        if (associationList.length > 0) {
            setAssociation(associationList.association)
        }
    }, [associationList])
    React.useEffect(() => {
        if (memberList.length > 0) {
            setMembers(memberList.member)
        }
    }, [memberList])
    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])
    React.useEffect(() => {
        if (projectList.length > 0) {
            setProjectLists(projectList.projects)
        }
    }, [projectList])

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (toolbarState.subproject) {
            const raw = {
                "project_id": toolbarState.subproject.value,
                "user_id": user.id
            }
            Apis.getPlan(raw).then((res) => {
                setTimeout(() => {
                    setPlanList(res.data.data.plans)
                    setWorkList([])
                }, 1000)


            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [toolbarState])

    React.useEffect(() => {
        if (toolbarState.phase_plane !== '$$all') {
            planList.map((elm) => {
                if (elm._id === toolbarState.phase_plane.value) {
                    elm.packages.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                    setWorkList(elm.packages)
                    return true
                }
                return true
            })

        }
    }, [planList, toolbarState.phase_plane])

    React.useEffect(() => {
        let resource = []
        if (teams.length > 0) {
            if (toolbarState.team && toolbarState.team !== '$$all') {
                teams.map((elm) => {
                    if (toolbarState.team.value === elm._id) {
                        let demo = {
                            id: elm._id,
                            name: elm.name,
                        }
                        resource.push(demo)
                    }
                    return resource
                })
            } else {
                teams.map((elm) => {
                    let demo = {
                        id: elm._id,
                        name: elm.name,
                    }
                    resource.push(demo)
                    return resource
                })
            }
        }
        setResources(resource)
    }, [teams, toolbarState.team])
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Look Ahead Plan | P2IC'></Header>
                <Sidebar title='Product_control' show='lookaheadplan'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Lookahead Plan</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Production Control</li>
                                            <li className="breadcrumb-item active">Lookahead Plan</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub-projext-sec ps-2" >

                            <div className='col-lg-12'>
                                <LapToolbar
                                    teams={teams.map((i) => ({ value: i._id, label: i.name }))}
                                    sub_project={projectLists.map((i) => ({ value: i._id, label: i.name }))}
                                    phase_plane={planList.map((i) => ({ value: i._id, label: i.name }))}
                                    work_packages={workList.map((i) => ({ value: i.value, label: i.label }))}
                                    status_codes={status_codes.map((i) => ({ value: i.value, label: i.label }))}
                                    onChange={onToolbarStateChange}
                                    handleShow={handleShow}
                                    state={toolbarState}
                                    attributes={attributes}
                                />
                            </div>
                            <div className='col-lg-12 gant_chart_lap' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}>

                                <GanttChart
                                    team={toolbarState.team}
                                    location_id={toolbarState.location_id}
                                    subproject={toolbarState.subproject}
                                    phase_plane={toolbarState.phase_plane}
                                    work_packages={toolbarState.work_packages}
                                    status_code={toolbarState.status_code}
                                    attributes={attributes}
                                    teams={teams}
                                    associations={associations}
                                    members={members}
                                    sub_project={projectLists}
                                    resources={resources}
                                    resourcesShow={resourcesShow}
                                    calanderData={state}
                                />
                            </div>

                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LAP