import React from 'react'
import { useParams } from "react-router-dom"
import Apis from '../../api';
const config = require('../../config')


function EmailVerfication() {

    const { id, token } = useParams()
    const [error, setError] = React.useState([])
    React.useEffect(() => {
        const raw = {
            userid: id,
            otp: token
        }
        Apis.verifyEmail(raw).then((res) => {
            window.localStorage.setItem('user-token', JSON.stringify(res.data.data))
            
            window.location.replace(config.server_base_url + "/reset-password")
        }).catch((error) => {
            setError(error.response.data.error)
            window.location.replace(config.server_base_url + "/404")

        })
    }, [id, token])

    return (
        <>
            <p>{error}</p>

        </>
    )
}

export default EmailVerfication
