import React, { useEffect, useState, useRef } from 'react'
import { LineChart, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from 'recharts'
import createTrend from "trendline"
import keyBy from 'lodash/keyBy'
import Apis from '../../../../api'

const transformCommitmentPlans = (plan) => ({
    ...plan,
    commit_date: new Date(plan.commit_date),
    closing_date: new Date(plan.closing_date),
})
const dateFormatter = (value) =>

    new Date(value).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: '2-digit',

    })

const LineChartMargin = {
    top: 5,
    right: 30,
    left: 20,
    bottom: 30
}
/* const XAxisDomain = ['auto', 'auto'] */
const XAxisPading = { left: 60, right: 60 }
const XAxisTick = { fontSize: '0.8em' }
const YAxisDomain = [0, 100]
/* const YAxisLabel = { value: 'PPC (Percent Planned Complete), %', angle: -90, position: 'insideLeft' } */
/* const XAxisLabel = { value: 'Reliable  Report (PPC)', offset: 5, position: 'bottom', } */
const LineActiveDot = { r: 8 }
const LegendWrapperStyle = { lineHeight: 4 }
const labelFormatter = (value) => new Date(value).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',

})

const Ppcgraph = ({ teamId, fromDate, toDate, teams, subproject, phase_plane, work_packages, location_id, showTrendLine = false }) => {

    const [commitmentPlans, setCommitmentPlans] = useState([])
    const [commitmentPlan, setCommitmentPlan] = useState([])
    useEffect(() => {
        const filter = [{ is_active: false }]
        let filters = [{ type: 'Task' }]
        let demo
        if (teamId && teamId !== '$$all') {
            demo = { team_id: teamId }
            filter.push(demo)
        }
        if (subproject && subproject.value !== '$$all') {
            demo = { "subproject.value": subproject.value }
            filters.push(demo)
        }
        if (phase_plane && phase_plane.value !== '$$all') {
            demo = { "phase_plane.value": phase_plane.value }
            filters.push(demo)
        }
        if (work_packages && work_packages.value !== '$$all') {
            demo = { "work_packages.value": work_packages.value }
            filters.push(demo)
        }
        if (fromDate) {
            demo = { closing_date: { $gte: new Date(fromDate).getTime() } }
            filter.push(demo)
        }
        if (toDate) {
            demo = { closing_date: { $lte: new Date(toDate).getTime() } }
            filter.push(demo)
        }
        if (location_id) {
            demo = { location_id: location_id }
            filters.push(demo)
        }

        const raw = {
            "filter": filter,
            "filters": filters
        }
        Apis.getAnalytice(raw).then((res) => {
            setCommitmentPlans(res.data)

        }).catch((error) => {
            console.log(error.response.data)
        })

    }, [fromDate, teamId, toDate, subproject, phase_plane, work_packages, location_id])

    const transformedCommitmentPlans = commitmentPlan
        .map(transformCommitmentPlans)
        .sort((a, b) => a.closing_date.getTime() - b.closing_date.getTime())


    const teamsById = keyBy(teams, '_id')

    const findArryIndex = (arr, date, id) => {
        for (var i = 0; i < arr.length; i++) {
            var el = arr[i];
            if (el.team_id === id && el.closing_date === date) {
                return i
            }
        }
        return -1

    }

    useEffect(() => {
        let list = []
        const plans = commitmentPlans.commit ? commitmentPlans.commit : []
        const tasks = commitmentPlans.tasks ? commitmentPlans.tasks : []
        plans.map((p) => {
            const task = tasks.find(elm => elm._id == p.task_id)
            if (task) {
                let demo = {
                    closing_date: p.closing_date,
                    team_id: p.team_id,
                    completed_tasks_count: p.completed_tasks_count,
                    not_completed_tasks_count: p.not_completed_tasks_count
                }
                if (list.length === 0) {
                    list.push(demo)
                } else {
                    let index = findArryIndex(list, p.closing_date, p.team_id)
                    if (index === -1) {
                        list.push(demo)
                    } else {
                        list[index].completed_tasks_count = parseInt(list[index].completed_tasks_count) + parseInt(p.completed_tasks_count)
                        list[index].not_completed_tasks_count = parseInt(list[index].not_completed_tasks_count) + parseInt(p.not_completed_tasks_count)
                    }
                }
            }

            return list
        })
        setCommitmentPlan(list)
    }, [commitmentPlans])


    const PPCGraphData = transformedCommitmentPlans.map((p) => {

        return (
            {
                date: p.closing_date.getTime(),
                amount:
                    p.completed_tasks_count === 0 && p.not_completed_tasks_count === 0
                        ? 0
                        : (p.completed_tasks_count / (p.completed_tasks_count + p.not_completed_tasks_count)) * 100,

                [p.team_id]:
                    p.completed_tasks_count === 0 && p.not_completed_tasks_count === 0
                        ? 0
                        : (p.completed_tasks_count / (p.completed_tasks_count + p.not_completed_tasks_count)) * 100,

            })

    })

    const timestamps = PPCGraphData.map((data) => data.date);
    const xMax = Math.max(...timestamps);
    const xMin = Math.min(...timestamps);
    const trendData = () => {
        const trend = createTrend(PPCGraphData, "date", "amount");
        return [
            { 'amount': trend.calcY(xMin), date: xMin },
            { 'amount': trend.calcY(xMax), date: xMax }
        ];
    };

    const wrapperRef = useRef()
    return (
        <>
            <div style={{ height: '600px' }}>
                <ResponsiveContainer width="100%" height="100%" ref={wrapperRef}>
                    <LineChart margin={LineChartMargin} width={wrapperRef.current?.clientWidth} data={PPCGraphData}>
                        {/* <CartesianGrid stroke='#f8f8f8' vertical={false} /> */}
                        <XAxis
                            name="Time"
                            type="number"
                            dataKey="date"
                            domain={["dataMin", "dataMax"]}
                            tickFormatter={dateFormatter}
                            angle={25}
                            dy={15}
                            dx={25}
                            axisLine={true}
                            tick={XAxisTick}
                            height={70}
                            fill="#5b626b"
                            tickLine={false}
                            padding={XAxisPading}
                            interval="preserveStartEnd"
                        /*  label={XAxisLabel} */
                        />
                        <YAxis
                            tickFormatter={(value) => Math.round(value)}
                            tickCount={12}
                            axisLine={false}
                            tickLine={false}
                            /* label={YAxisLabel} */
                            type="number"
                            allowDecimals={true}
                            domain={YAxisDomain} />
                        <Tooltip
                            labelFormatter={labelFormatter}
                            formatter={(value, name) => [`${Math.round(value)}%`, teamsById[name]?.name ? teamsById[name]?.name : "Trend Line"]}
                        />

                        <Legend
                            wrapperStyle={LegendWrapperStyle}
                            verticalAlign="top"
                            formatter={(value) => teamsById[value]?.name ? teamsById[value]?.name : "Trend Line"}
                        />

                        {teams.map((t, key) => {
                            if (teamId && teamId !== '$$all') {
                                if (t._id === teamId) {
                                    return (
                                        <Line
                                            activeDot={LineActiveDot}
                                            connectNulls
                                            key={t._id}
                                            stroke={t.color}
                                            fill={t.color}
                                            type="monotoneX"
                                            dataKey={t._id}
                                            strokeWidth={2}

                                        />
                                    )
                                }
                            } else {
                                return (
                                    <Line
                                        activeDot={LineActiveDot}
                                        connectNulls
                                        key={t._id}
                                        stroke={t.color}
                                        fill={t.color}
                                        type="monotoneX"
                                        dataKey={t._id}
                                        strokeWidth={2}

                                    />
                                )
                            }
                        })}
                        {showTrendLine ? <><Line
                            data={trendData()}
                            dataKey="amount"
                            stroke="black"

                        /> </> : <></>}




                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default Ppcgraph