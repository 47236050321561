import React from 'react';
export default class Input extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reason_description: props.reason_description
        };

    }

    render() {
        const handleChange = (event) => {
            const value = event.target.value
            const reason_code_id = event.target.getAttribute("data-reason_code_id")
            const id = event.target.getAttribute("data-id")
            const raw = {
                "id": id,
                "markedAsCommit": false,
                "markedAsUnCommit": true,
                "reason_code_id": reason_code_id,
                "reason_description": value,
            }
            if (reason_code_id !== '61e5aa3673ef5a540682b237') {
                this.setState({ reason_description: value })
                this.props.updateTask(raw)
            }

        }
        const ReasonSelectRegion = (props) => {
            if (props.markedAsUnCommit) {
                return (<> <input type="text"
                    className="form-control"
                    onChange={handleChange}
                    data-id={props.id}
                    data-reason_code_id={props.reason_code_id}
                    value={this.state.reason_description} />
                </>)
            }
        }
        return (<>{ReasonSelectRegion(this.props)}</>)

    }
}