import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Alert from '../../../helpers/Alert'
import CollaboratorApi from './CollaboratorApi'
import { SketchPicker } from 'react-color'
import EditableProjectCalendar from '../../../helpers/EditableProjectCalendar'
import Table from './components/Table'
import { default as ReactSelect } from "react-select"

const showAllOption = { value: '', label: '-Select Team-' }
const showAllOptionMemeber = { value: '', label: '-Select Member-' }
function ProjectCollaborator() {
    const { error,
        teamList,
        memberList,
        associationList,
        state,
        values,
        PROJECT_CALENDAR_DAYS,
        handleClick,
        handleAdd,
        handleColorChange,
        handleChange,
        onTeamChange,
        handleTeamSubmit,
        handleMemberSubmit,
        handleDeleteTeam,
        handleDeleteMember,
        handleDeleteAssociation,
        handleEditTeam,
        handleAssociationSubmit,
        handleProjectCalendarEditStart,
        handleProjectCalendarCancel,
        handleForgetPassword,
        handleProjectCalendarSave } = CollaboratorApi()


    const styles = {
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        colors: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${values.color}`,
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
            left: '70px',
            top: '10px'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },

    }

    const TeamList = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.team.map((elm, index) => {
                if (elm.status === 1) {
                    return (
                        <>
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{elm.name}</td>
                                <td>{elm.abrv}</td>
                                <td>{elm.handle}</td>
                                <td>{elm.planning}</td>
                                <td>
                                    <div>
                                        <button type="button" className="btn btn-secondary" style={{ background: elm.color, width: '100%', height: '20px' }}></button>
                                    </div>
                                </td>


                                <td>
                                    <div className="d-flex addremove_btn">
                                        <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Teammodal" data-bs-placement="bottom" title="Edit" data-bs-whatever="@mdo" onClick={() => handleEditTeam(elm, 'team')}><i className="fas fa-pencil-alt"></i></button>
                                        <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteTeam(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </>
                    )

                } else {
                    return (
                        <>
                            <tr>
                                <th scope="row"><strike>{index + 1}</strike></th>
                                <td><strike>{elm.name}</strike></td>
                                <td><strike>{elm.abrv}</strike></td>
                                <td><strike>{elm.handle}</strike></td>
                                <td><strike>{elm.planning}</strike></td>
                                <td>
                                    <div>
                                        <button type="button" className="btn btn-secondary" style={{ background: elm.color, width: '100%', height: '20px' }}></button>
                                    </div>
                                </td>


                                <td>
                                    <div className="d-flex addremove_btn">
                                        <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteTeam(elm._id, 1)}><i class="fas fa-trash-restore"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </>
                    )

                }

            }))
        }
        return list

    }

    const MembersSelect = (lists) => {
        let list = []
        if (lists.length > 0) {
            lists.member.map((elm, index) => {
                if (elm.status === 1) {
                    const demo = {
                        value: elm._id,
                        label: elm.first_name + ' ' + elm.last_name
                    }
                    list.push(demo)
                    return list
                }
                return list
            })
        }
        return list
    }


    const TeamSelect = (lists) => {

        let list = []
        if (lists.length > 0) {
            lists.team.map((elm, index) => {
                if (elm.status === 1) {
                    const demo = {
                        value: elm._id,
                        label: elm.name
                    }
                    list.push(demo)
                    return list
                }
                return list
            })
            return list
        }
        return list

    }

    const MemberList = (lists) => {
        let list = null
        if (lists.length > 0) {
            return (list = lists.member.map((elm, index) => {
                if (elm.status === 1) {
                    if (elm.is_registered === false) {
                        return (
                            <>
                                <tr className="not_register">
                                    <th scope="row">{index + 1}</th>
                                    <td>{elm.first_name}</td>
                                    <td>{elm.last_name}</td>
                                    <td>{elm.abrv}</td>
                                    <td>{elm.handle}</td>
                                    <td>{elm.email}</td>
                                    {/* <td>{elm.last_login_date ? new Date(elm.last_login_date) : 'N.A'}</td> */}

                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                            <button className="btn btn-primary btn-sm me-1" onClick={() => handleDeleteMember(elm._id, 0)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive"><i className="fas fa-trash-alt"></i></button>
                                            <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Resend" onClick={() => handleForgetPassword(elm.email)}>Resend</button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )

                    } else {
                        return (
                            <>
                                <tr >
                                    <th scope="row">{index + 1}</th>
                                    <td>{elm.first_name}</td>
                                    <td>{elm.last_name}</td>
                                    <td>{elm.abrv}</td>
                                    <td>{elm.handle}</td>
                                    <td>{elm.email}</td>
                                    {/* <td>{elm.last_login_date ? new Date(elm.last_login_date) : 'N.A'}</td> */}

                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                            <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteMember(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    }
                } else {
                    if (elm.is_registered === false) {
                        return (
                            <>
                                <tr className="not_register">
                                    <th scope="row"><strike>{index + 1}</strike></th>
                                    <td><strike>{elm.first_name}</strike></td>
                                    <td><strike>{elm.last_name}</strike></td>
                                    <td><strike>{elm.abrv}</strike></td>
                                    <td><strike>{elm.handle}</strike></td>
                                    <td><strike>{elm.email}</strike></td>
                                    {/*  <td><strike>{elm.last_login_date ? new Date(elm.last_login_date) : 'N.A'}</strike></td> */}

                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteMember(elm._id, 1)} ><i class="fas fa-trash-restore"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <tr>
                                    <th scope="row"><strike>{index + 1}</strike></th>
                                    <td><strike>{elm.first_name}</strike></td>
                                    <td><strike>{elm.last_name}</strike></td>
                                    <td><strike>{elm.abrv}</strike></td>
                                    <td><strike>{elm.handle}</strike></td>
                                    <td><strike>{elm.email}</strike></td>
                                    {/*   <td><strike>{elm.last_login_date ? new Date(elm.last_login_date) : 'N.A'}</strike></td> */}

                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button className="btn btn-primary btn-sm" onClick={() => handleDeleteMember(elm._id, 1)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" ><i class="fas fa-trash-restore"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    }

                }

            }))
        }
        return list
    }




    return (
        <>
            <div id="layout-wrapper">
                <Header title='Project Collaborators | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='projectCollaborators'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">

                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Project Collaborators</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Project Attributes</li>
                                            <li className="breadcrumb-item active">Collaborators</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow colaborate_page">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Teams</h4>
                                                        <div className="paroject_datepicker">
                                                            <button type="button" className="btn btn-primary mx-1 float-start" data-bs-toggle="modal" data-bs-target="#Teammodal" data-bs-whatever="@mdo" onClick={handleAdd}>Add<i className="fas fa-plus"></i></button>
                                                            <div className="modal fade" id="Teammodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered team_modalbox">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="exampleModalLabel">{(values.id !== '') ? 'Update Team' : 'Add Team'}</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <form>
                                                                                <div className="row">
                                                                                    <div className="col-lg-6">
                                                                                        <div className="mb-3">
                                                                                            <label htmlFor="recipient-name" className="col-form-label">Team Name:</label>
                                                                                            <input type="text" className="form-control" id="recipient-name" name='team_name' value={values.team_name} onChange={handleChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className="mb-3">
                                                                                            <label htmlFor="recipient-name" className="col-form-label">Abrv.</label>
                                                                                            <input type="text" className="form-control" id="recipient-name" name='team_abrv' value={values.team_abrv} onChange={handleChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-lg-6">
                                                                                        <div className="mb-3">
                                                                                            <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                            <input type="text" className="form-control" id="recipient-name" name='team_handle' value={values.team_handle} readOnly />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className="mb-3">
                                                                                            <label htmlFor="recipient-name" className="col-form-label">Planning Horizon</label>
                                                                                            <select className="form-select" aria-label="Default select example" name='team_planning' value={values.team_planning} onChange={handleChange}>
                                                                                                <option value=''>Select Planning Horizon</option>
                                                                                                <option value="Daily">Daily</option>
                                                                                                <option value="Weekly">Weekly</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Color</label>
                                                                                        <div>
                                                                                            <div style={styles.swatch} onClick={handleClick} >
                                                                                                <div style={styles.colors} />
                                                                                            </div>
                                                                                            {state.show_color_picker && (
                                                                                                <div style={styles.popover} >
                                                                                                    <div style={styles.cover} onClick={handleClick} />
                                                                                                    <SketchPicker
                                                                                                        color={values.color}
                                                                                                        onChangeComplete={handleColorChange} />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>

                                                                                    </div>

                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-primary" data-bs-dismiss={(values.team_planning !== '') ? "modal" : ""} onClick={() => handleTeamSubmit(values)}>{(values.id !== '') ? 'Update Team' : 'Add Team'}</button>
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <EditableProjectCalendar
                                                                editMode={state.isProjectCalendarEdited}
                                                                isLoading={state.isProjectCalendarLoading}
                                                                days={PROJECT_CALENDAR_DAYS}
                                                                disabled={state.isProjectCalendarLoading}
                                                                onEditStart={handleProjectCalendarEditStart}
                                                                onSave={handleProjectCalendarSave}
                                                                onCancel={handleProjectCalendarCancel}
                                                                defaultValues={state.projectCalendarValues}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Team Name</th>
                                                                    <th scope="col">Abrv.</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Planning Horizon</th>
                                                                    <th scope="col">Color</th>
                                                                    <th scope="col">Action</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {TeamList(teamList)}

                                                            </tbody>
                                                        </table>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">

                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Members</h4>
                                                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" onClick={handleAdd}>Add<i className="fas fa-plus"></i></button>
                                                        <div className="modal fade" id="Members" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered members_modalbox">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">{(values.id !== '') ? 'Update Member' : 'Add Member'}</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form>
                                                                            <div className="row">
                                                                                <div className="col-lg-4 mt-2">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">First Name</label>
                                                                                        <input type="text" className="form-control" id="recipient-name" name='first_name' value={values.first_name} onChange={handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4 mt-2">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Last Name</label>
                                                                                        <input type="text" className="form-control" id="recipient-name" name='last_name' value={values.last_name} onChange={handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4 mt-2">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Abrv.</label>
                                                                                        <input type="text" className="form-control" id="recipient-name" name='member_abrv' value={values.member_abrv} onChange={handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4 mt-2">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                        <input type="text" className="form-control" id="recipient-name" name='member_handle' value={values.member_handle} readOnly />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4 mt-2">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Email</label>
                                                                                        <input type="email" className="form-control" id="recipient-name" name='email' value={values.email} onChange={handleChange} disabled={(values.id !== '') ? true : false} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.email !== '') ? "modal" : ""} onClick={() => handleMemberSubmit(values)}>{(values.id !== '') ? 'Update Member' : 'Add Member'}</button>
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">First Name</th>
                                                                    <th scope="col">Last Name</th>
                                                                    <th scope="col">Abrv.</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Email</th>
                                                                    {/* <th scope="col">Last login </th> */}

                                                                    <th scope="col">Action</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {MemberList(memberList)}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Team Member Association</h4>
                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#TeamAssociation" data-bs-whatever="@mdo" onClick={handleAdd}>Add<i className="fas fa-plus"></i></button>



                                                <div className="modal fade" id="TeamAssociation" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered TeamAssociation-modal">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Add Team Member Association</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-lg-4 mt-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Team</label>
                                                                                <ReactSelect
                                                                                    options={TeamSelect(teamList)}
                                                                                    closeMenuOnSelect={true}
                                                                                    hideSelectedOptions={false}
                                                                                    name="team_id"
                                                                                    isClearable
                                                                                    value={values.team ?? showAllOption}
                                                                                    onChange={onTeamChange}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 mt-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Member</label>
                                                                                <ReactSelect
                                                                                    options={MembersSelect(memberList)}
                                                                                    closeMenuOnSelect={true}
                                                                                    hideSelectedOptions={false}
                                                                                    name="member_id"
                                                                                    isClearable
                                                                                    value={values.member ?? showAllOptionMemeber}
                                                                                    onChange={onTeamChange}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-4 mt-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Role</label>

                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" id="flexRadioDefault1" name='role' value='Administrator' onChange={handleChange} checked={(values.role === 'Administrator') ? true : false} />
                                                                                    <label className="form-check-label mb-0" htmlFor="flexRadioDefault1">
                                                                                        Administrator
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" id="flexRadioDefault2" name='role' value='Member' onChange={handleChange} checked={(values.role === 'Member') ? true : false} />
                                                                                    <label className="form-check-label mb-0" htmlFor="flexRadioDefault2">
                                                                                        Member
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-primary" data-bs-dismiss={(values.role !== '') ? "modal" : ""} onClick={() => handleAssociationSubmit(values)} >Add Member</button>
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                            <div className="table-responsive">
                                                <Table
                                                    data={associationList}
                                                    handleDeleteAssociation={handleDeleteAssociation}
                                                    teamList={teamList}
                                                    memberList={memberList} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default ProjectCollaborator