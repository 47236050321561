import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Modal from 'react-bootstrap/Modal'
import Alert from '../../../helpers/Alert'
import validator from 'validator'
import Suppliers from './Suppliers'
import Apis from '../../../api'

function MaterialManagerSuppliers() {
    const [show, setShow] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false)
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const [formData, setFormData] = React.useState({
        company_name: '',
        phone: '',
        email: '',
        address: '',
        first_name: '',
        last_name: '',
        notes: '',
        id: ""
    });


    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
     /*    if (name === 'phone') {
            if (!/^\d*$/.test(value)) {
                setError({
                    ...error,
                    error: 'Please Enter valid Phone',
                    color: 'error',
                    open: true
                })
            } else {
                setError({
                    ...error,
                    open: false
                })
            }
        } */
       /*  if (name === 'email') {
            if (!validator.isEmail(value)) {
                setError({
                    ...error,
                    error: 'Enter valid email!',
                    color: 'error',
                    open: true
                })
            } else {
                setError({
                    ...error,
                    open: false
                })
            }
        } */
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleEdit = (data) => {
        setFormData({
            company_name: data.company_name,
            phone: data.phone,
            email: data.email,
            address: data.address,
            first_name: data.first_name,
            last_name: data.last_name,
            notes: data.notes,
            id: data._id
        })
        setShow(!show)
    }
    /* Add and update suppliers */
    const handleSubmit = (data) => {
        if (data.company_name === '') {
            setError({
                ...error,
                error: 'Company is required',
                color: 'error',
                open: true
            })

        } else if (data.phone === '' || !/^\d*$/.test(data.phone)) {
            setError({
                ...error,
                error: 'Enter vaild Phone',
                color: 'error',
                open: true
            })

        } else if (data.address === '') {
            setError({
                ...error,
                error: 'Address is required',
                color: 'error',
                open: true
            })
        } else if (data.email === '' || !validator.isEmail(data.email)) {
            setError({
                ...error,
                error: 'Enter vaild email',
                color: 'error',
                open: true
            })
        } else if (data.first_name === '') {
            setError({
                ...error,
                error: 'First Name  is required',
                color: 'error',
                open: true
            })
        } else if (data.last_name === '') {
            setError({
                ...error,
                error: 'Last Name  is required',
                color: 'error',
                open: true
            })
        } else {
            let raw
            if (data.id === "") {
                raw = {
                    company_name: data.company_name,
                    address: data.address,
                    phone: data.phone,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    notes: data.notes,
                }
                Apis.addSuppliers(raw).then((res) => {
                    setError({
                        ...error,
                        error: '',
                        color: '',
                        open: false
                    })
                    setFormData({
                        company_name: '',
                        phone: '',
                        email: '',
                        address: '',
                        first_name: '',
                        last_name: '',
                        notes: '',
                        id: ""
                    })
                    setRefresh(!refresh)
                    setShow(!show)

                }).catch((errors) => {
                    setError({
                        ...error,
                        error: errors.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                raw = {
                    id: data.id,
                    company_name: data.company_name,
                    address: data.address,
                    phone: data.phone,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    notes: data.notes,
                }
                Apis.updateSupplier(raw).then((res) => {
                    setError({
                        ...error,
                        error: '',
                        color: '',
                        open: false
                    })
                    setFormData({
                        company_name: '',
                        phone: '',
                        email: '',
                        address: '',
                        first_name: '',
                        last_name: '',
                        notes: '',
                        id: ""
                    })
                    setRefresh(!refresh)
                    setShow(!show)

                }).catch((errors) => {
                    setError({
                        ...error,
                        error: errors.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }

    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='Suppliers | P2IC'></Header>
                <Sidebar title='Material_managment' show='Suppliers'></Sidebar>
                <Alert error={error}></Alert>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Suppliers</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Material Manager</li>
                                            <li className="breadcrumb-item active">Suppliers</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Suppliers</h4>
                                                        <button type="button" className="btn btn-primary" onClick={() => setShow(!show)} >Add<i className="fas fa-plus"></i></button>
                                                    </div>
                                                    <Suppliers
                                                        refresh={refresh}
                                                        handleEdit={handleEdit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShow(!show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{formData.id === '' ? 'Add New Supplier' : 'Update Supplier'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="company_name" className='col-form-label'>Company</label>
                                    <input className="form-control" type="text" name="company_name" id="company_name" value={formData.company_name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="phone" className='col-form-label'>Phone</label>
                                    <input className="form-control" type="text" name="phone" id="phone" value={formData.phone} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="address" className='col-form-label'>Address</label>
                                    <input className="form-control" type="text" name="address" id="address" value={formData.address} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="email" className='col-form-label'>Email(Direct Contact)</label>
                                    <input className="form-control" type="text" name="email" id="email" value={formData.email} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="first_name" className='col-form-label'>First Name (Direct Contact)</label>
                                    <input className="form-control" type="text" name="first_name" id="first_name" value={formData.first_name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="last_name" className='col-form-label'>Last Name(Direct Contact)</label>
                                    <input className="form-control" type="text" name="last_name" id="last_name" value={formData.last_name} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="notes" className='col-form-label'>Notes</label>
                                    <input className="form-control" type="text" name="notes" id="notes" value={formData.notes} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit(formData)}>{formData.id === '' ? 'Add Supplier' : 'Update Supplier'}</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setShow(!show)}>Close</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default MaterialManagerSuppliers