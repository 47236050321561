import React from 'react'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Footer from '../../Layout/Footer'
import Alert from '../../../../helpers/Alert'
import Apis from '../../../../api'
import Modal from 'react-bootstrap/Modal'
import Attribute from './attribute'

function MaterialAttribute() {
    const [show, setShow] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false)
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [formData, setFormData] = React.useState({
        unit_tag: '',
        unit_handle: '',
        id: "",
        owner: user.id
    });


    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setFormData({
            ...formData,
            [name]: value,
            unit_handle: '@' + value.toLowerCase().replace(/ /g, '')
        })
    }
    const handleEdit = (data) => {
        setFormData({
            unit_tag: data.tag_name,
            unit_handle: data.handle,
            id: data._id
        })
        setShow(!show)
    }
    const handleSubmit = (formData) => {
        if (formData.unit_tag === '' || formData.unit_tag === undefined) {
            setError({
                ...error,
                error: 'Unit of measurement is required',
                color: 'error',
                open: true
            })
        } else if (formData.unit_handle === '' || formData.unit_handle === undefined) {
            setError({
                ...error,
                error: 'Unit handle is required',
                color: 'error',
                open: true
            })
        } else {
            if (formData.id !== '') {
                const raw = {
                    'id': formData.id,
                    'attribute_name': "Material UOM",
                    'tag_name': formData.unit_tag,
                    'handle': formData.unit_handle
                }
                Apis.updatematerialAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setFormData({
                        ...formData,
                        unit_tag: '',
                        unit_handle: '',
                        id: ''
                    })
                    setShow(!show)
                    setRefresh(!refresh)

                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            } else {
                const raw = {
                    'attribute_name': "Material UOM",
                    'tag_name': formData.unit_tag,
                    'handle': formData.unit_handle,
                    'owner': user.id
                }
                Apis.addmaterialAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setFormData({
                        ...formData,
                        unit_tag: '',
                        unit_handle: '',
                        id: ''
                    })
                    setShow(!show)
                    setRefresh(!refresh)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    React.useEffect(() => {
        if (show) {
            setError({
                ...error,
                error: '',
                color: '',
                open: false
            })
        }
    }, [show, refresh])
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Material Attributes | P2IC'></Header>
                <Sidebar title='Material_managment' show='MaterialAttribute'></Sidebar>
                <Alert error={error}></Alert>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Material Attributes</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Material Manager</li>
                                            <li className="breadcrumb-item active">Material Attributes</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Unit of Measurement</h4>
                                                        <button type="button" className="btn btn-primary" onClick={() => setShow(!show)} >Add<i className="fas fa-plus"></i></button>
                                                    </div>
                                                    <Attribute
                                                        refresh={refresh}
                                                        handleEdit={handleEdit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShow(!show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{formData.id === '' ? 'Add Unit of Measurement' : 'Update Unit of Measurement'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="company_name" className='col-form-label'>Unit of Measurement</label>
                                    <input className="form-control" type="text" name="unit_tag" id="company_name" value={formData.unit_tag} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="unit_handle" className='col-form-label'>Handle</label>
                                    <input className="form-control" type="text" name="unit_handle" id="unit_handle" value={formData.unit_handle} onChange={handleChange} readOnly />
                                </div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit(formData)}>{formData.id === '' ? 'Add UOM' : 'Update UOM'}</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setShow(!show)}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MaterialAttribute