import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sidebar from '../Layout/Sidebar'
import AttributeApiCall from './AttributeApiCall'
import Alert from '../../../helpers/Alert'
import { CompactPicker } from 'react-color'


function ProjectTags() {
    const { attributes,
        values,
        error,
        state,
        popUp,
        handleChange,
        deleteAttribute,
        handleClick,
        handleColorChange,
        handleSubmitTag,
        handlePopUp,
        handleClose,
        handleEdit } = AttributeApiCall()

    const styles = {
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        colors: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${values.color}`,
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },

    }

    const Location_list = (lists) => {
        let list = null
        let i = 1
        if (lists.length > 0) {
            return (list = lists.projectattribute.map((elm) => {
                if (elm.attribute_name === "Location") {
                    if (elm.status === 1) {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <div>{elm.tag_name}</div>
                                    </td>
                                    <td>{elm.handle}</td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button type="button" data-bs-target="#loactionTag" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal"  data-bs-placement="bottom"  title="Edit" onClick={() => handleEdit(elm)} ><i className="fas fa-pencil-alt"></i></button>
                                            <button onClick={() => deleteAttribute(elm._id, 0)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Archive "><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <tr>
                                    <th scope="row"><strike>{i++}</strike></th>
                                    <td>
                                        <strike> <div>{elm.tag_name}</div></strike>
                                    </td>
                                    <td><strike>{elm.handle}</strike></td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button onClick={() => deleteAttribute(elm._id, 1)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Restore"><i class="fas fa-trash-restore"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )

                    }

                }
                return list
            }))
        }
        return list

    }
    const Work_list = (lists) => {
        let list = null
        let i = 1
        if (lists.length > 0) {
            return (list = lists.projectattribute.map((elm) => {
                if (elm.attribute_name === "Work Package") {
                    if (elm.status === 1) {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <div>{elm.tag_name}</div>
                                    </td>
                                    <td>{elm.handle}</td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button type="button" data-bs-target="#workPackage" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-whatever="@mdo"  data-bs-placement="bottom"  title="Edit" onClick={() => handleEdit(elm)} ><i className="fas fa-pencil-alt"></i></button>
                                            <button onClick={() => deleteAttribute(elm._id, 0)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Archive"><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <strike><div>{elm.tag_name}</div></strike>
                                    </td>
                                    <td><strike>{elm.handle}</strike></td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button onClick={() => deleteAttribute(elm._id, 1)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Restore"><i class="fas fa-trash-restore"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    }
                }
                return list
            }))
        }
        return list
    } 

    const Discipline_list = (lists) => {
        let list = null
        let i = 1
        if (lists.length > 0) {
            return (list = lists.projectattribute.map((elm) => {
                if (elm.attribute_name === "Discipline") {
                    if (elm.status === 1) {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <div>{elm.tag_name}</div>
                                    </td>
                                    <td><div style={{ width: '36px', height: '14px', 'borderRadius': '2px', background: elm.color }}></div></td>
                                    <td>{elm.handle}</td>
                                    <td>
                                        <div className="d-flex addremove_btn">

                                            <button type="button" data-bs-target="#disciplanetags" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-whatever="@mdo" data-bs-placement="bottom"  title="Edit" onClick={() => handleEdit(elm)} ><i className="fas fa-pencil-alt"></i></button>
                                            <button onClick={() => deleteAttribute(elm._id, 0)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Archive"><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <tr>
                                    <th scope="row">{i++}</th>
                                    <td>
                                        <strike> <div>{elm.tag_name}</div></strike>
                                    </td>
                                    <td><strike><div style={{ width: '36px', height: '14px', 'borderRadius': '2px', background: elm.color }}></div></strike></td>
                                    <td><strike>{elm.handle}</strike></td>
                                    <td>
                                        <div className="d-flex addremove_btn">
                                            <button onClick={() => deleteAttribute(elm._id, 1)} className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Restore"><i class="fas fa-trash-restore"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )

                    }

                }
                return list
            }))
        }
        return list
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='Project Tags | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='projectTags'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Project Tags</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Project Attributes</li>
                                            <li className="breadcrumb-item active">Project Tags</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Work Package Tags</h4>
                                                        <button type="button" data-bs-target="#workPackage" className="btn btn-primary" data-bs-toggle="modal" data-bs-whatever="@mdo" onClick={() => handlePopUp('Work Package')}>Add<i className="fas fa-plus"></i></button>
                                                        <div id="workPackage" className={"modal fade " + popUp.workPackage} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={(popUp.workPackage === 'show') ? { display: 'block' } : {}}>
                                                            <div className="modal-dialog modal-dialog-centered location_modal">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">Work Package Tag</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form>
                                                                            <div className="row align-items-center">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Work Package Tag</label>
                                                                                        <input type="text" name='work_tag' onChange={handleChange} value={values.work_tag} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                        <input type="text" className="form-control" name='work_handle' value={values.work_handle} onChange={handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.work_tag !== '') ? "modal" : ""} onClick={() => handleSubmitTag(values, 'Work Package')}> {(values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Work Package Tag</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Work_list(attributes)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Discipline Tags</h4>
                                                        <div className="paroject_datepicker">
                                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#disciplanetags" onClick={() => handlePopUp('Discipline')} data-bs-whatever="@mdo">Add<i className="fas fa-plus"></i></button>
                                                            <div id="disciplanetags" className={"modal fade " + popUp.discipline} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={(popUp.discipline === 'show') ? { display: 'block' } : {}}>
                                                                <div className="modal-dialog modal-dialog-centered disciplanetags_modal">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="exampleModalLabel">Discipline Tag</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <form>

                                                                                <div className="row align-items-center">
                                                                                    <div className="col-lg-5">
                                                                                        <div className="mb-3">
                                                                                            <label htmlFor="recipient-name" className="col-form-label">Discipline Tag</label>
                                                                                            <input type="text" className="form-control" name='tag_name' value={values.tag_name} onChange={handleChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-5">
                                                                                        <div className="mb-3">
                                                                                            <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                            <input type="text" className="form-control" name='handle' value={values.handle} onChange={handleChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-2">
                                                                                        <div>
                                                                                            <label htmlFor="recipient-name" className="col-form-label">Color</label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div style={styles.swatch} onClick={handleClick} >
                                                                                                <div style={styles.colors} />
                                                                                            </div>
                                                                                            {state.show_color_picker && (
                                                                                                <div style={styles.popover} >
                                                                                                    <div style={styles.cover} onClick={handleClick} />
                                                                                                    <CompactPicker
                                                                                                        value={state.show_color_picker}
                                                                                                        colors={state.color_picker}
                                                                                                        onChange={handleColorChange}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-primary" data-bs-dismiss={(values.tag_name !== '') ? "modal" : ""} onClick={() => handleSubmitTag(values, 'Discipline')}>{(values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Discipline Tag</th>
                                                                    <th scope="col">Color</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Discipline_list(attributes)}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Location Tags</h4>
                                                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#loactionTag" data-bs-whatever="@mdo" onClick={() => handlePopUp('Location')}>Add<i className="fas fa-plus"></i></button>
                                                        <div id="loactionTag" className={"modal fade " + popUp.location} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={(popUp.location === 'show') ? { display: 'block' } : {}}>
                                                            <div className="modal-dialog modal-dialog-centered location_modal">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">Location Tag</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form>

                                                                            <div className="row align-items-center">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Location Tag</label>
                                                                                        <input type="text" name='loction_tag' onChange={handleChange} value={values.loction_tag} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                        <input type="text" className="form-control" name='location_handle' value={values.location_handle} onChange={handleChange} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.loction_tag !== '') ? "modal" : ""} onClick={() => handleSubmitTag(values, 'Location')}> {(values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Location Tag</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Location_list(attributes)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default ProjectTags
