import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { default as ReactSelect } from "react-select"
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Footer from '../../Layout/Footer'
import Alert from '../../../../helpers/Alert'
import Apis from '../../../../api'
import Equipment from './equipment'
import keyBy from 'lodash/keyBy'


function Equipments() {
    const [show, setShow] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false)
    const [suppliersList, setSupplierList] = React.useState([])
    const [supplier, setSupplier] = React.useState([])
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const [formData, setFormData] = React.useState({
        supplier: '',
        equipment: '',
        id: "",
        description: '',
    });

    React.useEffect(() => {
        Apis.getSuppliers().then((res) => {
            setSupplierList(res.data.data.suppliers)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    React.useEffect(() => {
        let list = [];
        if (suppliersList.length !== 0) {
            suppliersList.map((elm) => {
                if (elm.status) {
                    var demo = {
                        value: elm._id,
                        label: elm.company_name
                    }
                    list.push(demo)
                }
                return list
            })
        }
        setSupplier(list)

    }, [suppliersList])
    React.useEffect(() => {
        if (show) {
            setError({
                ...error,
                error: '',
                color: '',
                open: false
            })
        }
    }, [show, refresh])
    const supplierById = keyBy(suppliersList, '_id')

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setFormData({
            ...formData,
            [name]: value,
        })
    }
    const handleSupplierChange = (option, params) => {
        setFormData({
            ...formData,
            [params.name]: option,
        })
    }

    const handleSubmit = (data) => {
        if (data.supplier === '' || data.supplier === null) {
            setError({
                ...error,
                error: 'Supplier is required',
                color: 'error',
                open: true
            })
        } else if (data.equipment === '') {
            setError({
                ...error,
                error: 'Equipment is required',
                color: 'error',
                open: true
            })
        } else {
            if (data.id !== '') {
                const raw = {
                    "equipment": data.equipment,
                    "description": data.description,
                    "supplier": data.supplier.value,
                    "id": data.id
                }
                Apis.updateMaterialEquiment(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setFormData({
                        supplier: '',
                        equipment: '',
                        id: "",
                        description: '',
                    })
                    setRefresh(!refresh)
                    setShow(!show)

                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            } else {
                const raw = {
                    "equipment": data.equipment,
                    "description": data.description,
                    "supplier": data.supplier.value,
                    "timestamp": new Date().getTime()
                }
                Apis.addMaterialEquipment(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setFormData({
                        supplier: '',
                        equipment: '',
                        id: "",
                        description: '',
                    })
                    setRefresh(!refresh)
                    setShow(!show)

                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            }
        }
        console.log(data)
    }

    const handleEdit = (list) => {
        setFormData({
            supplier: {
                value: list.supplier,
                label: supplierById[list.supplier]?.company_name
            },
            equipment: list.equipment,
            id: list._id,
            description: list.description,
        })
        setShow(!show)
    }


    return (
        <>
            <div id="layout-wrapper">
                <Header title='Equipment | P2IC'></Header>
                <Sidebar title='Material_managment' show='Equipments'></Sidebar>
                <Alert error={error}></Alert>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Equipment</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Material Manager</li>
                                            <li className="breadcrumb-item active">Equipment</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <h4 className="card-title">Equipment</h4>
                                                        <button type="button" className="btn btn-primary" onClick={() => setShow(!show)} >Add<i className="fas fa-plus"></i></button>
                                                    </div>
                                                    <Equipment
                                                        refresh={refresh}
                                                        handleEdit={handleEdit}
                                                        suppliers={suppliersList}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShow(!show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{formData.id === '' ? 'Add New Equipment' : 'Update Equipment'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="supplier" className='col-form-label'>Company/Supplier</label>
                                    <ReactSelect
                                        options={supplier}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        value={formData.supplier}
                                        name="supplier"
                                        isClearable
                                        onChange={handleSupplierChange}
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="equipment" className='col-form-label'>Equipment Name</label>
                                    <input className="form-control" type="text" name="equipment" id="equipment" value={formData.equipment} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="description" className='col-form-label'>Description</label>
                                    <input className="form-control" type="text" name="description" id="description" value={formData.description} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit(formData)}>{formData.id === '' ? 'Add Equipment' : 'Update Equipment'}</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setShow(!show)}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Equipments