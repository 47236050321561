import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { default as ReactSelect } from "react-select"
import { components } from "react-select"
import Modal from 'react-bootstrap/Modal'

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

const SECONDARY_OPTION_PREFIX = '$$'
const SECONDARY_SELECT_OPTIONS = {
    ALL: `${SECONDARY_OPTION_PREFIX}all`,
    ADD: `${SECONDARY_OPTION_PREFIX}add`,
    Status_ALl: {
        value: 2,
        label: 'Work in Process'
    },
}
const showAllOption = { value: SECONDARY_SELECT_OPTIONS.ALL, label: '-Show All-' }

const AnalyticsAdditionalToolbar = ({ state = [], sub_project = [], phase_plane = [], work_packages = [], onChange, attributes }) => {

    const [location_pop, setLocationPopup] = React.useState(false)
    const [location_List, setLocationLists] = React.useState([])
    const [filters, setfilter] = React.useState({
        location: ''
    })
    const [show, setShow] = React.useState(false)

    React.useEffect(() => {
        let demo
        let list = []
        if (attributes.length > 0) {
            attributes.projectattribute.map((elm) => {
                if (elm.attribute_name === 'Location' /* && elm.status !== 0 */) {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    list.push(demo)
                }
                return list
            })
            setLocationLists(list)
        }
    }, [attributes])

    var subValues, phaseValue, workVAlue = SECONDARY_SELECT_OPTIONS.ALL


    if (state['subproject']) {
        subValues = sub_project.find((i) => i.value === state['subproject'].value)
        if (state['subproject'].value === '$$all') {
            phaseValue = SECONDARY_SELECT_OPTIONS.ALL
            workVAlue = SECONDARY_SELECT_OPTIONS.ALL
        }
    }
    if (state['phase_plane']) {
        phaseValue = phase_plane.find((i) => i.value === state['phase_plane'].value)
        if (state['phase_plane'].value === '$$all') {
            workVAlue = SECONDARY_SELECT_OPTIONS.ALL
        }
    }
    if (state['work_packages']) {
        workVAlue = work_packages.find((i) => i.value === state['work_packages'].value)
    }
    React.useEffect(() => {
        if (!state['location_id']) {
            setfilter({
                ...filters,
                location: ''
            })
            setShow(false)
        }
    }, [state])



    /* Change the value of dropdowns */

    const handleClearFilter = () => {
        setfilter({
            ...filters,
            location: ''
        })
        setShow(!show)
        onChange({
            name: 'location_id',
            value: '',
        })
    }


    const handleChange = (selected) => {
        if (selected.length !== 0) {
            setfilter({
                ...filters,
                location: selected
            })
        } else {
            setfilter({
                ...filters,
                location: ''
            })
        }
    }

    const onhandleChange = (option, params) => {
        if (typeof onChange !== 'function') return
        if (params.name === 'status_code') {
            onChange({
                name: params.name,
                value: params.action === 'clear' ? SECONDARY_SELECT_OPTIONS.Status_ALl : option,
            })
        } else {
            onChange({
                name: params.name,
                value: params.action === 'clear' ? SECONDARY_SELECT_OPTIONS.ALL : option,
            })
        }
        if (params.name === 'subproject') {
            onChange({
                name: 'phase_plane',
                value: SECONDARY_SELECT_OPTIONS.ALL,
            })
            onChange({
                name: 'work_packages',
                value: SECONDARY_SELECT_OPTIONS.ALL,
            })
        }
        if (params.name === 'phase_plane') {
            onChange({
                name: 'work_packages',
                value: SECONDARY_SELECT_OPTIONS.ALL,
            })
        }
    }

    const handleApplyFilter = (filters) => {
        setLocationPopup(false)
        setShow(!show)
        onChange({
            name: 'location_id',
            value: filters.location,
        })
    }
    return (
        <>
            <Row>
                <Col className="col-lg-2">
                    <div className="mb-2">
                        <label className="col-form-label">Sub Project</label>
                        <ReactSelect
                            defaultValue={showAllOption}
                            options={[showAllOption, ...sub_project]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            value={subValues ?? showAllOption}
                            name="subproject"
                            isClearable
                            onChange={onhandleChange}
                        />
                    </div>
                </Col>
                <Col className="col-lg-2">
                    <div className="mb-2">
                        <label className="col-form-label">Phase Plan</label>
                        <ReactSelect
                            defaultValue={showAllOption}
                            options={[showAllOption, ...phase_plane]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            value={phaseValue ?? showAllOption}
                            isDisabled={phase_plane.length === 0 ? true : false}
                            name="phase_plane"
                            isClearable
                            onChange={onhandleChange}
                        />
                    </div>
                </Col>
                <Col className="col-lg-2">
                    <div className="mb-2">
                        <label className="col-form-label">Work Package</label>
                        <ReactSelect
                            defaultValue={showAllOption}
                            options={[showAllOption, ...work_packages]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            value={workVAlue ?? showAllOption}
                            isDisabled={work_packages.length === 0 ? true : false}
                            name="work_packages"
                            isClearable
                            onChange={onhandleChange}
                        />
                    </div>
                </Col>
                <Col className="col-lg-2">
                    <div className="mb-2">
                        <Row className="justify-content-between">
                            <label className="col-form-label">Location Filter</label>
                            <Col className="col-log-6">
                                <Button
                                    color="primary"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Location Filter"
                                    onClick={() => setLocationPopup(!location_pop)}>
                                    <i className='mdi mdi-filter'></i>
                                </Button>
                            </Col>
                            <Col className={show ? "col-log-6" : "col-log-6 d-none"}>
                                <Button
                                    color="danger"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Clear Filter"
                                    onClick={handleClearFilter}>
                                    <i className='fa fa-times'></i>
                                </Button>
                            </Col>
                            <Col className="col-log-6"></Col>
                        </Row>



                    </div>
                </Col>
            </Row>
            {/* LOcation filter popup */}

            <Modal show={location_pop} aria-labelledby="contained-modal-title-vcenter" onHide={() => setLocationPopup(!location_pop)} centered >
                <Modal.Header closeButton>
                    <Modal.Title> Location Filter </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='mb-2'>
                                    <label className="col-form-label">Location</label>
                                    <ReactSelect
                                        options={location_List}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option
                                        }}
                                        onChange={(selected) => handleChange(selected)}
                                        value={filters.location}
                                        allowSelectAll={true}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleApplyFilter(filters)} >Apply Filter</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setLocationPopup(!location_pop)}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AnalyticsAdditionalToolbar