import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import CapToolbar from './helper/CapToolbar'
import CapGhanttChart from './helper/CapGhanttChart'
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import ProjectApi from '../Projects/ProjectApi'
import Apis from '../../../api'
import AttributeApiCall from '../ProjectTags/AttributeApiCall'
import Alert from '../../../helpers/Alert'

function CAP() {
    const { teamList, state } = CollaboratorApi()
    const { projectList } = ProjectApi()
    const { attributes } = AttributeApiCall()

    const [teams, setTeams] = React.useState([])
    const [projectLists, setProjectLists] = React.useState([])
    const [planList, setPlanList] = React.useState([])
    const [workList, setWorkList] = React.useState([])
    const [resources, setResources] = React.useState([])
    const [toolbarState, setToolbarState] = React.useState({})
    const [resourcesShow, setResourceShow] = React.useState(false);

    const [commit_id, setCommit_ids] = React.useState([])
    const [close_commit_id, setCloseCommit_ids] = React.useState([])
    const [commit_length, setCommitLength] = React.useState(0)
    const [refresh, setRefresh] = React.useState(false);

    const [commit_button, setButton] = React.useState({
        text: 'Commit Plan',
        pop_up_text: "Commit the plan?",
        function: 'add',
    })
    const [commit, setCommit] = React.useState(true)
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })



    /* Filters */
    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])
    React.useEffect(() => {
        if (projectList.length > 0) {
            setProjectLists(projectList.projects)
        }
    }, [projectList])
    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (toolbarState.subproject) {
            const raw = {
                "project_id": toolbarState.subproject.value,
                "user_id": user.id
            }
            Apis.getPlan(raw).then((res) => {
                setPlanList(res.data.data.plans)
                setWorkList([])

            }).catch((error) => {
                console.log(error.response.data)
            })
        }
    }, [toolbarState])
    React.useEffect(() => {
        if (toolbarState.phase_plane !== '$$all') {
            planList.map((elm) => {
                if (elm._id === toolbarState.phase_plane.value) {
                    elm.packages.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))  
                    setWorkList(elm.packages)
                    return true
                }
                return true
            })
        }
    }, [planList, toolbarState])

    React.useEffect(() => {
        let resource = []
        if (teams.length > 0) {
            if (toolbarState.team && toolbarState.team !== '$$all') {
                teams.map((elm) => {
                    if (toolbarState.team.value === elm._id) {
                        let demo = {
                            id: elm._id,
                            name: elm.name,
                        }
                        resource.push(demo)
                    }
                    return resource
                })
            }
        }
        setResources(resource)
    }, [teams, toolbarState])
    const onChange = ({ name, text, value }) => {
        setButton({
            text: name,
            pop_up_text: text,
            function: value,
        })
    }

    const commitShow = ({ value }) => {
        setCommit(value)
    }
    const commitLength = ({ value }) => {
        setCommitLength(value)
    }

    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }

    const handleShow = (value) => {
        setResourceShow(value)
    }
    const setCommit_id = (value) => {
        setCommit_ids(value)
    }
    const setCloseCommit_id = (value) => {
        setCloseCommit_ids(value)
        setError({
            ...error,
            open: false
        })
    }

    const taskRefresh = () => {
        setRefresh(Math.random())
    }

    const commitTask = ({ value }) => {
        if (commit_button.function === 'add') {
            const user = JSON.parse(localStorage.getItem('user-info'))
            const team = toolbarState.team
            const time = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).getTime()
            const raw = {
                team: team,
                commit_id: commit_id,
                commit_date: new Date().getTime(),
                user: user,
                time: time
            }
            Apis.addcommitmentplan(raw).then((res) => {
                setToolbarState({
                    ...toolbarState,
                    ['work_packages']: toolbarState.work_packages === '$$all' ? '$$alll' : '$$all',
                    ['phase_plane']: toolbarState.phase_plane === '$$all' ? '$$alll' : '$$all',
                    ['subproject']: toolbarState.subproject === '$$all' ? '$$alll' : '$$all'
                })
                setCommit_ids([])
                setCommit(true)
            }).catch((error) => {
                console.log(error)
            })
        } else if (commit_button.function === 'close') {
            let length = parseInt(close_commit_id.task.length) + parseInt(close_commit_id.not_task.length)
            console.log(length)
            console.log(commit_length)
            if (length !== commit_length) {
                setError({
                    ...error,
                    error: 'Failed to close commitment plan',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    commit_id: close_commit_id,
                    closing_date: new Date().getTime(),
                    closedate: new Date(new Date().setHours(0, 0, 0, 0)).getTime()
                }
                Apis.closecommitmentplannotcomplete(raw).then((res) => {
                    if (res.data.success === true) {
                        Apis.closecommitmentplan(raw).then((response) => {
                            setCommit(true)
                            setCloseCommit_ids([])
                            setCommit_ids([])
                            setToolbarState({
                                ...toolbarState,
                                ['work_packages']: toolbarState.work_packages === '$$all' ? '$$alll' : '$$all',
                                ['phase_plane']: toolbarState.phase_plane === '$$all' ? '$$alll' : '$$all',
                                ['subproject']: toolbarState.subproject === '$$all' ? '$$alll' : '$$all'
                            })
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
        }

    }


    return (
        <>
            <div id="layout-wrapper">
                <Alert error={error}></Alert>
                <Header title='Commitment-plan | P2IC'></Header>
                <Sidebar title='Product_control' show='CommitmentPlan'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Commitment Plan</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Production Control</li>
                                            <li className="breadcrumb-item active">Commitment Plan</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-projext-sec ps-2" >
                                <div className='col-lg-12'>
                                    <CapToolbar
                                        state={toolbarState}
                                        teams={teams.map((i) => ({ value: i._id, label: i.name }))}
                                        sub_project={projectLists.map((i) => ({ value: i._id, label: i.name }))}
                                        phase_plane={planList.map((i) => ({ value: i._id, label: i.name }))}
                                        work_packages={workList.map((i) => ({ value: i.value, label: i.label }))}
                                        commit_button={commit_button}
                                        commit={commit}
                                        attributes={attributes}
                                        onChange={onToolbarStateChange}
                                        handleShow={handleShow}
                                        commitTask={commitTask}
                                        taskRefresh={taskRefresh}
                                    />
                                </div>
                                <div className='col-lg-12 gant_chart_lap' style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1
                                }}>
                                    <CapGhanttChart
                                        team={toolbarState.team}
                                        location_id={toolbarState.location_id}
                                        subproject={toolbarState.subproject}
                                        phase_plane={toolbarState.phase_plane}
                                        work_packages={toolbarState.work_packages}
                                        resources={resources}
                                        attributes={attributes}
                                        resourcesShow={resourcesShow}
                                        setCommit_id={setCommit_id}
                                        commitShow={commitShow}
                                        onChange={onChange}
                                        commitLength={commitLength}
                                        setCloseCommit_id={setCloseCommit_id}
                                        calanderData={state}
                                        taskRefresh={refresh}
                                    />
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CAP