const TableBody = ({ tableData, columns, handleDeleteAssociation }) => {

  return (
    <tbody>
      {tableData.map((data) => {     
        if (data.status === 1) {
          return (
            <tr key={data.id}>
              {columns.map(({ accessor }) => {
                const tData = data[accessor] ? data[accessor] : "——";
                if (accessor === 'action') {
                  return <td key={accessor}>  <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Archive" onClick={() => handleDeleteAssociation(data.id, 0)}><i className="fas fa-trash-alt"></i></button></td>;

                } else {
                  return <td key={accessor}>{tData}</td>;
                }


              })}
            </tr>
          );
        } else {
          return (
            <tr key={data.id}>
              {columns.map(({ accessor }) => {
                const tData = data[accessor] ? data[accessor] : "——";
                if (accessor === 'action') {
                  return <td key={accessor}>  <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Restore" onClick={() => handleDeleteAssociation(data.id, 1)}><i className="fas fa-trash-restore"></i></button></td>;

                } else {
                  return <td key={accessor}><strike>{tData}</strike></td>;
                }


              })}
            </tr>
          );
        }

      })}
    </tbody>
  );
};

export default TableBody;
