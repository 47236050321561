import TableBody from "./TableBody";
import TableHead from "./TableHead";
import { useSortableTable } from "./useSortableTable";
import React from 'react'
import './table.css'


const Table = ({ data, teamList, memberList, handleDeleteAssociation }) => {
    const TeamName = (id, lists) => {
        let name = undefined
        if (lists.length > 0) {
            return (name = lists.team.map((team) => {
                if (team._id === id) {
                    return team.name
                }
                return name
            }))
        }
        return name
    }
    const MemberName = (id, lists) => {
        let name = undefined
        if (lists.length > 0) {
            return (name = lists.member.map((member) => {
                if (member._id === id) {
                    return (member.first_name + ' ' + member.last_name)
                }
                return name
            }))
        }
        return name
    }

    const [associationrow, setAssociationList] = React.useState([])
    React.useEffect(() => {
        let list = []
        let demo = null
        const associationList = data

        if (associationList.length > 0) {
            if (teamList.length > 0 && memberList.length > 0) {
                associationList.association.map((elm, index) => {
                    let team_name = TeamName(elm.team_id, teamList)
                    team_name = team_name.filter(function (element) {
                        return element !== undefined;
                    });
                    let mamber_name = MemberName(elm.member_id, memberList)
                    mamber_name = mamber_name.filter(function (element) {
                        return element !== undefined;
                    });

                    demo = {
                        id: elm._id,                      
                        team: team_name[0],
                        member: mamber_name[0],
                        role: elm.role,
                        status: elm.status,
                    }
                    list.push(demo)
                    return list
                })
            }

            setAssociationList(list)
        }

    }, [data, teamList, memberList])
    const columns = [       
        { label: "Team", accessor: "team", sortable: true, sortbyOrder: "asc" },
        { label: "Member", accessor: "member", sortable: true },
        { label: "Role", accessor: "role", sortable: false },
        { label: "Action", accessor: "action", sortable: false },
    ];
    const [tableData, handleSorting] = useSortableTable(associationrow, columns);

    return (
        <>
            <table className="table table-hover table-centered table-nowrap mb-0">
                <TableHead {...{ columns, handleSorting }} />
                <TableBody {...{ columns, tableData, handleDeleteAssociation }} />
            </table>
        </>
    );
};

export default Table;
