import React from 'react'
import { Link } from 'react-router-dom'
import Apis from '../../../api'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../helpers/Alert'


function Sidebar(props) {
    const navigate = useNavigate()
    const [drop, setDrop] = React.useState({
        drop_1_active: 0,
        drop_2_active: 0,
        drop_3_active: 0,
        drop_4_active: 0,
        drop_5_active: 0,
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const [system_password, setPassword] = React.useState()
    const [show_loader, setLoaderShow] = React.useState(false)
    const [show_id, setShowId] = React.useState('')
    const handleShow = (tag) => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        if (user.role === 'member') {
            setError({
                ...error,
                error: 'Unrestricted area',
                color: 'error',
                open: true
            })
        } else {
            setLoaderShow(true)
            setShowId(tag)
        }
    }

    React.useEffect(() => {
        setError({
            ...error,
            error: 'Unrestricted area',
            color: 'error',
            open: false
        })
        if (props.title === 'Product_control') {
            setDrop({
                drop_1_active: 1,
                drop_2_active: 0,
                drop_3_active: 0,
                drop_4_active: 0,
                drop_5_active: 0
            })
        } else if (props.title === 'projectAttr') {
            setDrop({
                drop_1_active: 0,
                drop_2_active: 1,
                drop_3_active: 0,
                drop_4_active: 0,
                drop_5_active: 0
            })
        } else if (props.title === 'CostTracker') {
            setDrop({
                drop_1_active: 0,
                drop_2_active: 0,
                drop_3_active: 1,
                drop_4_active: 0,
                drop_5_active: 0
            })

        } else if (props.title === 'DigitalDesign') {
            setDrop({
                drop_1_active: 0,
                drop_2_active: 0,
                drop_3_active: 0,
                drop_4_active: 1,
                drop_5_active: 0
            })

        } else if (props.title === 'Material_managment') {
            setDrop({
                drop_1_active: 0,
                drop_2_active: 0,
                drop_3_active: 0,
                drop_4_active: 0,
                drop_5_active: 1
            })

        }
    }, [props])


    const open = (id) => {
        if (id === 1) {
            if (drop.drop_1_active === 1) {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            } else {
                setDrop({
                    drop_1_active: 1,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            }
        } else if (id === 2) {
            if (drop.drop_2_active === 1) {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            } else {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 1,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            }
        } else if (id === 3) {
            if (drop.drop_3_active === 1) {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            } else {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 1,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            }
        } else if (id === 4) {
            if (drop.drop_4_active === 1) {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            } else {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 1,
                    drop_5_active: 0
                })

            }
        } else if (id === 5) {
            if (drop.drop_5_active === 1) {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 0
                })
            } else {
                setDrop({
                    drop_1_active: 0,
                    drop_2_active: 0,
                    drop_3_active: 0,
                    drop_4_active: 0,
                    drop_5_active: 1
                })

            }
        }

    }
    const handleCheckSystemPassword = (password, show_id) => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        const raw = {
            'id': user.id,
            'key': "system_password",
            'system_password': password
        }
        Apis.checkSystemInfo(raw).then((res) => {
            setLoaderShow(false)
            setPassword()
            navigate('/' + show_id)
        }).catch((error) => {
            console.log(error.response.data.error)
        })
    }

    return (
        <>
            <Alert error={error}></Alert>
            <Modal show={show_loader} aria-labelledby="contained-modal-title-vcenter" onHide={() => setLoaderShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm System Password </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <input type="password" name='system_password' value={system_password} onChange={(event) => setPassword(event.target.value)} className="form-control" placeholder='Enter system password' />
                                </div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleCheckSystemPassword(system_password, show_id)} >Confirm</button>
                </Modal.Footer>

            </Modal>
            <div className="vertical-menu mm-active">
                <div data-simplebar="" className="mm-show">

                    <div id="sidebar-menu" className="mm-active">

                        <ul className="metismenu list-unstyled mm-show" id="side-menu">
                            <li className={(props.title === 'dashboard') ? "mm-active" : ""}>
                                <Link to="/dashboard" className={(props.title === 'dashboard') ? "waves-effect mm-active" : "waves-effect"}>
                                    <i className="ti-home"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li className="menu-title">Production System</li>
                            <li className={(drop.drop_1_active === 1) ? "mm-active" : ''} id="headingOne">
                                <Link to="#" className={(drop.drop_1_active === 1) ? "has-arrow waves-effect" : "has-arrow waves-effect mm-collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"} aria-expanded={(drop.drop_1_active === 1) ? "true" : "false"} aria-controls="collapse" onClick={() => open(1)} >
                                    <i className="ti-view-grid"></i>
                                    <span>Production Control</span>
                                </Link>
                                <ul className={(props.title === 'Product_control') ? "sub-menu collapse show" : "sub-menu collapse"} id="collapse" aria-labelledby="headingOne" data-bs-parent="#sidebar-menu" >
                                    {/* <li className={(props.show === 'processmapper') ? "mm-active" : ""}><Link to="/process-mapper">Process Mapper</Link></li> */}
                                    <li className={(props.show === 'constraintslog') ? "mm-active" : ""}><Link to="/constraints-log">Constraints log</Link></li>
                                    <li className={(props.show === 'lookaheadplan') ? "mm-active" : ""}><Link to="/look-ahead-plan">Lookahead Plan</Link></li>
                                    <li className={(props.show === 'CommitmentPlan') ? "mm-active" : ""}><Link to="/commitment-plan">Commitment Plan</Link></li>
                                    <li className={(props.show === 'ProcessLibrary') ? "mm-active" : ""} onClick={() => handleShow('process-library')}><Link to="" >Process Library</Link></li>

                                </ul>
                            </li>
                            <li className={(drop.drop_5_active === 1) ? "mm-active" : ''} id="headingFive">
                                <Link to="#" className={(drop.drop_5_active === 1) ? "has-arrow waves-effect" : "has-arrow waves-effect mm-collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_5"} aria-expanded={(drop.drop_5_active === 1) ? "true" : "false"} aria-controls="collapse" onClick={() => open(5)} >
                                    <i className="ti-package"></i>
                                    <span>Material Manager</span>
                                </Link>
                                <ul className={(props.title === 'Material_managment') ? "sub-menu collapse show" : "sub-menu collapse"} id="collapse_5" aria-labelledby="headingFive" data-bs-parent="#sidebar-menu" >
                                    <li className={(props.show === 'MaterialEquipmentLogs') ? "mm-active" : ""} ><Link to="/material-equipment-logs" >M/E Logs</Link></li>
                                    <li className={(props.show === 'Suppliers') ? "mm-active" : ""}><Link to="/suppliers">Suppliers</Link></li>
                                    <li className={(props.show === 'Material') ? "mm-active" : ""}><Link to="/material">Materials</Link></li>
                                    <li className={(props.show === 'Equipments') ? "mm-active" : ""}><Link to="/equipments">Equipment</Link></li>
                                    <li className={(props.show === 'MaterialAttribute') ? "mm-active" : ""} ><Link to="/material-attribute" >Material Attributes</Link></li>
                                </ul>
                            </li>

                            <li className={(drop.drop_3_active === 1) ? "mm-active" : ""} id="headingThree">
                                <Link to="#" className={(drop.drop_3_active === 1) ? "has-arrow waves-effect" : "has-arrow waves-effect mm-collapsed"} type="button" aria-expanded={(drop.drop_3_active === 1) ? "true" : "false"} data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-controls="#collapse_2" onClick={() => open(3)} >
                                    <i className="ti-money"></i>
                                    <span>Cost Tracker</span>
                                </Link>
                                <ul className={(props.title === 'CostTracker' || drop.drop_3_active === 1) ? "sub-menu collapse show" : "sub-menu collapse"} id="collapse_2" aria-labelledby="headingThree" data-bs-parent="#sidebar-menu" >
                                    <li className={(props.show === 'CostTrackMP') ? "mm-active" : ""} onClick={() => handleShow('cost-track-mp')} ><Link to="">Track-MP</Link></li>
                                    <li className={(props.show === 'CostComponents') ? "mm-active" : ""} onClick={() => handleShow('cost-components')}><Link to="" >Cost Components</Link></li>
                                    <li className={(props.show === 'CostCodes') ? "mm-active" : ""} onClick={() => handleShow('cost-codes')}><Link to="">Cost Codes</Link></li>
                                    <li li className={(props.show === 'CostAttributes') ? "mm-active" : ""} onClick={() => handleShow('cost-attributes')} ><Link to="">Cost Attributes</Link></li>
                                    <li className={(props.show === 'CostAnalyticsReports') ? "mm-active" : ""} onClick={() => handleShow('cost-analytics-reports')}><Link to="" >Analytics & Reports</Link></li>
                                </ul>
                            </li>
                            <li className={(drop.drop_4_active === 1) ? "mm-active" : ""} id="headingFour">
                                <Link to="#" className={(drop.drop_4_active === 1) ? "has-arrow waves-effect" : "has-arrow waves-effect mm-collapsed"} type="button" aria-expanded={(drop.drop_4_active === 1) ? "true" : "false"} data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-controls="collapse_3" onClick={() => open(4)} >
                                    <i className="ti-package"></i>
                                    <span>Digital Design</span>
                                </Link>
                                <ul className={(props.title === 'DigitalDesign' || drop.drop_4_active === 1) ? "sub-menucollapse show" : "sub-menu collapse"} id="collapse_3" aria-labelledby="headingFour" data-bs-parent="#sidebar-menu">
                                    <li onClick={() => handleShow('')}><Link to="">View Model</Link></li>
                                </ul>
                            </li>
                            <li className={(props.show === 'AnalyticsReports') ? "mm-active" : ""}>
                                <Link to="/reports">
                                    <i className="ti-view-grid"></i>
                                    <span>Analytics & Reports</span>
                                </Link>
                            </li>
                            <li className={(drop.drop_2_active === 1) ? "mm-active" : ""} id="headingTwo">
                                <Link to="#" className={(drop.drop_2_active === 1) ? "has-arrow waves-effect" : "has-arrow waves-effect mm-collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_1"} aria-expanded={(drop.drop_2_active === 1) ? "true" : "false"} aria-controls="collapse_1" onClick={() => open(2)}>
                                    <i className="ti-layout"></i>
                                    <span>Project Attributes</span>
                                </Link>
                                <ul className={(props.title === 'projectAttr') ? "sub-menu collapse show" : "sub-menu collapse"} id="collapse_1" aria-labelledby="headingTwo" data-bs-parent="#sidebar-menu">
                                    <li className={(props.show === 'workStructure') ? "mm-active" : ""}  ><Link to="/work-structure">Work Structure</Link></li>
                                    <li className={(props.show === 'projectTags') ? "mm-active" : ""}><Link to="/project-tags" >Project Tags</Link></li>
                                    <li className={(props.show === 'projectCollaborators') ? "mm-active" : ""}><Link to="/project-collaborators">Collaborators</Link></li>
                                    <li className={(props.show === 'reasonCodes') ? "mm-active" : ""}><Link to="/reason-codes" >Reason Codes</Link></li>
                                </ul>
                            </li>

                            <li >
                                <a href="https://www.blueocean.com.au/p2ic" target="_blank" className="waves-effect">
                                    <i className="ti-announcement"></i>
                                    <span>Help Guides</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div >
        </>
    )
}

export default Sidebar
